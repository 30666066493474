import React from 'react';
import { Box, Button, Card, CardContent, Grid } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { IConfigDialogProp } from '../../../../types';
import { useTranslation } from 'react-i18next';

/**
 * This component responsible to create the product card.
 * @prop {prod} is the product info
 * @callback {openDialog} is the call back function
 * @returns {JSX.Element}
 */

export const ProductCardComponent = ( { prod, openDialog }: { prod: IConfigDialogProp, openDialog: ( prod: IConfigDialogProp ) => void } ) => {
  const { t } = useTranslation();

  return (
    <Grid item className="catalog-Container" >
      <Card sx={ { border: '1px solid grey' } }>
        <CardContent className="catalog-cardLayout">
          <Box >
            <Box>
              <img src="public\png\SamplePMImage.png" className="catalog-image" alt="sample-pm-image" />
            </Box>

            <Box className="catalog-info" >
              <Box>
                <span
                  className="productCatalogCardHeader"
                >{prod.productId}
                </span>
              </Box>
              <Box className="catalog-productDescription">
                <span
                  className="text-capitalize productCatalogCardHeader"
                >{prod.productDescription}
                </span>
              </Box>

              <Box className="catalog-create-div" >
                <Button className="text-capitalize catalog-create" data-testid="catalogCreate" variant="contained" onClick={ () => openDialog( prod ) }>{'+ ' + t( 'button.configure' )}</Button>
              </Box>

            </Box>
            <Box>  <StarOutlineIcon /> </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}