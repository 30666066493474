import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRouter } from '.';
import { AuthProvider } from 'oidc-react';
import { authSettings } from '../api/OidcSettings';
import { SessionStore } from '../services/SessionStore';
import { UrlHelper,IsAppOpenedInIframe,IsAppOpenedByExtApp } from '../services';
import { EUrlParams, ESessionStore } from '../data/Constants';
import { IUrlParams } from '../../types';

function setInSessionStore( urlParams:IUrlParams, navigate:( path: object,replace:object ) => void ) {
  
  if ( urlParams.configurationId ) {
    SessionStore.set( ESessionStore.ConfigurationId, urlParams.configurationId );
  }
   
  if( urlParams.scope ) {
    SessionStore.set( ESessionStore.Scope, urlParams.scope );
    const param = UrlHelper.deleteSearchParameter( EUrlParams.Scope );
    navigate( {
      pathname: '/',
      search: param,
    }, {replace:true} );
  }

  if( urlParams.token ) {
    SessionStore.set( ESessionStore.Token, urlParams.token );
    const param = UrlHelper.deleteSearchParameter( EUrlParams.Token );
    navigate( {
      pathname: '/',
      search: param,
    }, {replace:true} );
  }

  if( urlParams.headerLess ) {
    SessionStore.set( ESessionStore.HeaderLess, urlParams.headerLess );
    const param = UrlHelper.deleteSearchParameter( EUrlParams.HeaderLess );
    navigate( {
      pathname: '/',
      search: param,
    }, {replace:true} );
  }

  //To store language value from URL Param
  if( urlParams.language ) {
    SessionStore.set( ESessionStore.Language, urlParams.language );
    const languageParam = UrlHelper.deleteSearchParameter( EUrlParams.Language );
    navigate( {
      pathname: '/',
      search: languageParam,
    }, {replace:true} );
  }

  if( urlParams.country ) {
  //To store country value from URL Param
    SessionStore.set( ESessionStore.Country, urlParams.country );
    const countryParam = UrlHelper.deleteSearchParameter( EUrlParams.Country );
    navigate( {
      pathname: '/',
      search: countryParam,
    }, {replace:true} );
  }

  if( urlParams.currency ) {
    //To store currency value from URL Param
    SessionStore.set( ESessionStore.Currency, urlParams.currency ? urlParams.currency.toUpperCase() : urlParams.currency );
    const currencyParam = UrlHelper.deleteSearchParameter( EUrlParams.Currency );
    navigate( {
      pathname: '/',
      search: currencyParam,
    }, {replace:true} );
  }
}

/**
 * Main component for authentication handling.
 * Skips authentication in developer mode
 * @returns {JSX.Element} the AppRouter component
 */
export const AuthComponent = () => {
  const configurationId = UrlHelper.getSearchParameter( EUrlParams.ConfigurationId );
  const scope = UrlHelper.getSearchParameter( EUrlParams.Scope );
  const token = UrlHelper.getSearchParameter( EUrlParams.Token );
  const language = UrlHelper.getSearchParameter( EUrlParams.Language );
  const country = UrlHelper.getSearchParameter( EUrlParams.Country );
  const currency = UrlHelper.getSearchParameter( EUrlParams.Currency );
  const headerLess = UrlHelper.getSearchParameter( EUrlParams.HeaderLess );
  
  const navigate = useNavigate();
  // To store configurationId, scope in Session Storage
 
  const [state, setState] = React.useState( {
    configdata: {
      AuthorityUrl: '',
      ClientId: '',
      Redirect: '',
      Redirect_Silent: '',
      Developer_Mode: false,
    },
  } );

  useEffect( () => {
    const urlParams:IUrlParams = {
      configurationId:configurationId,
      scope:scope,
      language:language,
      country:country,
      currency:currency,
      headerLess:headerLess,
      token:token
    }
    setInSessionStore( urlParams,navigate );
    setState( {
      ...state,
      configdata: authSettings,
    } );
  }, [] );

  useEffect( ()=>{
    if ( configurationId ) {
      SessionStore.set( ESessionStore.ConfigurationId, configurationId );
    }
  },[configurationId] ); //To update the session store on first render and whenever the config id changes(like new configutaion creation etc.,)
 
  const oidcConfig = {
    onSignIn: async () => {
      let params = '';
      if( SessionStore.get( ESessionStore.ConfigurationId ) ) {
        params = UrlHelper.createSearchParameter( EUrlParams.ConfigurationId, SessionStore.get( ESessionStore.ConfigurationId ) );
      }
      // Replace history before setting viewId so all unrelated query params are removed
      navigate( {
        pathname: '/',
        search: params,
      }, {replace:true} );
    },

    authority: state.configdata.AuthorityUrl,
    clientId: state.configdata.ClientId,
    automaticSilentRenew: true,
    grant_type: 'refresh_token',
    response_type: 'code token id_token',
    redirectUri: state.configdata.Redirect,
    silent_redirect_uri: state.configdata.Redirect_Silent,
    scope: state.configdata.ClientId + '/User.Read email openid profile',
    loadUserInfo: false,
    revokeAccessTokenOnSignout: true,
  };
  if ( !oidcConfig.authority ) {
    return null;
  }
  if ( state.configdata.Developer_Mode || IsAppOpenedInIframe() || IsAppOpenedByExtApp() ) {
    return <AppRouter />;
  }
  return (
    <AuthProvider { ...oidcConfig }>
      <AppRouter />
    </AuthProvider>
  );
};
