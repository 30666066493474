import { appSettings } from '../settings';
import { setError } from '../store/states/ErrorSlice';
import { IApiError } from '../../types/IApiTypes';
import { AppStore } from '../store/AppStore';

export const requestHeader: any = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'Philips_Ace_Configurator',
};

//Get the api headers 
//Returning headers only with Accept-Language if STAuth is true
//Token will be set from AxiosClient.ts when STAuth is true
export const getHeaders = ( token: string, lang?:string ) => {
  let headers: {[key: string]: string} = {};
  if( appSettings.STAuth ) {
    if( lang ) {
      return {headers: { 'Accept-Language':lang }};
    } else {
      return {headers: headers};
    }
    
  }

  headers = {'Authorization': `Bearer ${token}`};
  if( lang ) {
    headers['Accept-Language'] = lang;
  }

  return {headers: headers};
}

//this method dispatch the api error to the error state
export function handleError( err: IApiError, page?: string, data?: any ) {
  const errPayload = {
    code: err.response?.status || err.code,
    message: err.response?.data ? err.response.data.Message : err.message,
    page: page ?? '',
    data: data ?? null
  };
  AppStore.dispatch( setError( errPayload ) )

  return {
    response: err.response,
    error: errPayload,
    message: err.message,
    viewId: err?.response?.data,
  };
}