
import { AppStore } from '../store';
import { IFeatureOrFamily } from '../../types';
import { getCurrentModel, getFeatureCode, getLanguageCode } from './ConfigurationDataHelperFunctions';
import { isSubmodelCountVariable } from './SummaryHelperFunction';


/**
 * This method returns the longSalestext from the configuration longSalesText state
 * @param {IFeatureOrFamily} variable configuration variable 
 * @returns {string} returns the long sales text value
 */

export function getVariableLongSalesText( variable : IFeatureOrFamily ):string|undefined {
  const configuration = AppStore.getState().configuration;
  const productId = getCurrentModel( configuration );
  const longSalesText = configuration.longSalesText?.get( productId );
  const id = variable.id ? variable.id?.split( '.' ).pop() : variable.value;

  let longText:string|undefined = longSalesText?.get( id )?.text;
  
  //Get the Submodel count variable longText 
  if( isSubmodelCountVariable( variable ) ) {
    const subModelId = getFeatureCode( variable.id , variable.variableType );
    const subModelData = configuration.subModelData?.get( productId )?.find( data=>data.id === subModelId )
    const langCode = getLanguageCode();
    longText = subModelData?.longTexts?.find( lngText=>lngText.id === langCode )?.text;
  }

  return longText;
}