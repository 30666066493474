import { getPackagePath, getProductId } from '../services';
import { t } from 'i18next';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';
import { ApiUrl } from '../data/Constants';
import { IApiError, ISalesTextRequest } from '../../types/IApiTypes';


export const SalesTextApi = {
  salesText: ( action: ISalesTextRequest ) => {
    const productModel = action.modelId ? action.modelId : getProductId();
    return AxiosClient
      .get( ApiUrl.SalesTextApi, 
        { params: { packagePath: getPackagePath( getProductId() ), productModel: action.modelId, type: action.apiType },
          headers: getHeaders( action.token, action.language ).headers 
        },
      ).then( ( response ) => {
        return {
          salesText: response.data,
          apiType: action.apiType,
          modelId: productModel
        }
      } ).catch( ( err: IApiError ) => {
        if ( action.showError ) {
          return {
            error: {
              code: err.response?.status,
              message: t( 'salesText.notFound' )
            }
          }
        }
        return Promise.reject( new Error() );
      } )
  }
}