import { FormHelperText, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/lab';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { getDateValidationInfo, getRangeDate, toInputFormat } from '../../services';
import { ICustomMoment, IDateControlProps, IValidityInfo } from '../../../types';
import { gridLayout } from '../SectionLayout';
import { OptionalControl } from '..';

/**
 * Renders an input box for dates. 
 * Used for variables which allow the user to pick a date
 * @param {IDateControlProps} props the properties for the date input component
 * @returns {JSX.Element} the date input component
 */
export const DateControl = ( { featureFlags, value, variable, onChange, onOptionalChange } : IDateControlProps ) => {
  const [internalDateValue, setInternalDateValue] = useState( value ? moment( value ) : null );
  const [valueIsUpdated, setValueIsUpdated] = useState( false );
  const date = getSelectedDate( valueIsUpdated, internalDateValue, value );
  const validity = getDateValidationInfo( variable, date );
  const onEventChange = ( newValue: Moment | null ) => {
    setInternalDateValue( newValue );
    valueIsUpdated && setValueIsUpdated( false );

    if( !getDateValidationInfo( variable, newValue ).valid ) {
      //if not valid do not update configuration
      return;
    }

    if( newValue && !newValue.isValid() ) {
      return;
    }

    onChange( newValue ? newValue.toISOString() : '' );
    setValueIsUpdated( true );
  }

  return <Grid container item xs={ gridLayout.fullWidth } alignItems="center" rowGap={ 0.5 }>
    <Grid item xs={ gridLayout.xs.input } sm={ gridLayout.sm.input } justifyContent="left" alignItems="center">
      <DatePicker
        className="datepicker"
        value={ date }
        minDate={ getRangeDate( variable, 'lower' ) }
        maxDate={ getRangeDate( variable, 'upper' ) }
        onChange={ onEventChange }
        DialogProps={ { className: 'bg-red' } }
        renderInput={ ( params: React.JSX.IntrinsicAttributes & { variant: TextFieldVariants | undefined; } & Omit<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps, 'variant'> ) => {
          if( !date && params.inputProps ) {
            params.inputProps.value = '';
          }
      
          return <TextField { ...params } />;
        } }
      />
    </Grid>
    <Grid container item xs={ gridLayout.xs.infoIcon } sm={ gridLayout.sm.infoIcon } alignItems="center" justifyContent="center"/>
    <Grid container item xs={ gridLayout.xs.optional } sm={ gridLayout.sm.optional } alignItems="center" justifyContent="center">
      <OptionalControl variable={ variable } onOptionalChange={ onOptionalChange } featureFlag={ featureFlags.Optional }/> 
    </Grid>
    { renderValidityMessage( date as ICustomMoment, validity ) }
  </Grid>
}

const getSelectedDate = ( valueIsUpdated: boolean, internalDateValue: Moment | null, value: string ) => {
  if( !valueIsUpdated ) {
    return internalDateValue;
  }

  return value 
    ? moment( value ) 
    : null;
}

const renderValidityMessage = ( dateCopy: ICustomMoment | null, validity: IValidityInfo ) => {
  if( !dateCopy || dateCopy.isValid() && validity.valid ) {
    return null;
  }

  return <FormHelperText className="color-red">
    { !dateCopy.isValid()
      ? `${dateCopy._i} is invalid. The accepted format is MM/DD/YYYY`
      : `${toInputFormat( dateCopy._i )} is invalid. Valid values are: ${validity.validValues.join( ', ' )}`
    }
  </FormHelperText>;
}