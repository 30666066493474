import { getHeaders } from './HttpUtil';
import AxiosClient from './AxiosClient';
import { IAuthorizationRequest, IMyConfigurationRequest } from '../../types/IApiTypes';

export const UserApi = {
  myConfigurations: async ( payload: IMyConfigurationRequest ) => {
    const { token, page, limit, productSearch, applicationIds, channelCodes } = payload;
    let parmas = { Page: page, Limit: limit };
    if ( productSearch ) {
      const search = { search: productSearch }
      parmas = { ...search, ...parmas };
    }
    try {
      const response = await AxiosClient
        .post( '/user/v1/configurations/read',
          {
            applicationIds: applicationIds ?? [],
            channels: channelCodes ?? []
          },
          { params: parmas, headers: getHeaders( token )?.headers }
        );
      const total = JSON.parse( response.headers['x-pagination'] ).Total;
      return {
        myConfigurations: response.data === '' ? [] : response.data,
        pageDetails: { page: page, totalRecords: total, limit: limit }
      };
    } catch {
      return null;
    }
  },

  getCategories: async ( action: IAuthorizationRequest ) => {
    try {
      const response = await AxiosClient
        .get( '/user/v1/configurations/filter/read',
          getHeaders( action.token )
        );
      return {
        category: response.data
      };
    } catch {
      return null;
    }
  },
}