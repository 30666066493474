import React, { lazy, memo, useEffect, useState } from 'react';
import { AppDispatch, AppState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Pagination, Stack } from '@mui/material';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import { RecordsPerPage, EFilterRootOption } from '../../../data/Constants';
import { IMyConfiguration, IMyConfigurationFilter, IMyConfigurationFilterOptions, IMyConfigurationParams, ISimpleTreeProps } from '../../../../types';
import { getCategories, getMyConfigurations, updateMyConfigPageDetails } from '../../../store/states/UserSlice';
import { filterIds, prepareMyConfigurationTreeCodes } from '../../../store/Helpers/LandingHelper';
import { SelectComponent } from '../../InputComponents/SelectComponent';
import { ConfigurationCardItem } from './ConfigurationCardItem';
import { IMyConfigurationRequest } from '../../../../types/IApiTypes';
const SimpleTreeViewComponent = lazy( () => import( '../../SimpleTreeViewComponent' ) );

/**
 * This component perform the role to show the configuration, filter and pagination.
 * @returns {JSX.Element} return the Myconfigurations component
 */
const MyConfigurations = () => {
  const initialFilterOptionValue = { channels: [], applications: [] };
  const auth = useAuth();
  const token = auth?.userData?.access_token ?? '';
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  //selectors
  const configuration = useSelector( ( state: AppState ) => state.configuration );
  const user = useSelector( ( state: AppState ) => state.user )

  const [myConfigs, setMyConfigs] = useState<IMyConfiguration[]>( [] );
  const [recordsPerPage, setRecordsPerPage] = useState<number>( RecordsPerPage[0] );
  const [totalMyConfigRecords, setTotalMyConfigRecords] = useState<number>( 1 );
  const [myConfigPage, setMyConfigPage] = useState<number>( 1 );
  const [filterOptions, setFilterOptions] = useState<IMyConfigurationFilterOptions>( initialFilterOptionValue );
  const [availableFilterCodes, setAvailableFilterCodes] = useState<IMyConfigurationFilter>( initialFilterOptionValue );
  const [filteredCodes, setFilteredCodes] = useState<IMyConfigurationFilter>( initialFilterOptionValue );

  /**
   * This function responsible for calling the fetch my configration
   * @prop {page} is the current page
   * @prop {limit} is the limit of recods per page
   * @prop {productSearch} is the searched value
   * @prop {selectedCodes} is the selected codes in the filter
   * @returns {void}
   */
  const fetchMyConfigurations = ( { page, limit, productSearch, selectedCodes = filteredCodes }: IMyConfigurationParams ): void => {
    const applications = filterIds( selectedCodes.applications, EFilterRootOption.Application );
    const channels = filterIds( selectedCodes.channels, EFilterRootOption.Channel );
    const params: IMyConfigurationRequest = {
      token: token,
      page: page,
      limit: limit,
      productSearch: productSearch,
      applicationIds: applications,
      channelCodes: channels
    }
    dispatch( getMyConfigurations( params ) );
  }

  /**
   * Fetch Categories
   * @returns {void}
   */
  const fetchCategories = ():void => {
    dispatch( getCategories( { token: token } ) );
  }

  /**
   * Called on pagination change
   * @param {React.ChangeEvent<unknown> _event} is the change event
   * @param {number value} is the current selected page
   * @requires void
   */

  const handleChange = ( _event: React.ChangeEvent<unknown>, value: number ): void => {
    const { applications, channels } = filteredCodes;
    const { totalRecords } = user.myConfigPageDetails;
    if ( !user.myConfigurations[value] ) {
      fetchMyConfigurations( { page: value, limit: recordsPerPage, productSearch: configuration.productSearchValue } )
    } else {
      setMyConfigs( user.myConfigurations[value] );
    }
    setMyConfigPage( value );
    dispatch( updateMyConfigPageDetails( { page: value, limit: recordsPerPage, totalRecords: totalRecords, applicationIds: applications, channelCodes: channels } ) );
  }

  /**
   * Called on record per page change performed
   * @param {React.ChangeEvent event} is the change event used for get the value 
   * @returns void
   */

  const handleChangeForRPP = ( event: React.ChangeEvent ): void => {
    const { value } = event.target;
    setRecordsPerPage( value );
    fetchMyConfigurations( { page: 1, limit: value, productSearch: configuration.productSearchValue } )
  }

  /**
   * Fetch my configuration and categories on initial load
   */

  useEffect( () => {
    fetchMyConfigurations( { page: myConfigPage, limit: recordsPerPage, productSearch: '' } )
    fetchCategories();
  }, [] )

  /**
   * Update the states on change of my configuration or category
   */

  useEffect( () => {
    const { page, limit, totalRecords } = user.myConfigPageDetails;
    setMyConfigPage( page );
    setRecordsPerPage( limit );
    setTotalMyConfigRecords( Math.ceil( totalRecords / limit ) || 1 );
    setMyConfigs( user.myConfigurations[page] || [] );
    if ( user.category ) {
      setFilterOptions( user.category );
    }
    const filterCodes = prepareMyConfigurationTreeCodes( user.category );
    setAvailableFilterCodes( filterCodes );
  }, [user.myConfigurations, user.category] )

  /**
   * The filterTreeProps is the schema for the creating filter tree on the myconfiguration left pane
   */

  const filterTreeProps: ISimpleTreeProps = {
    treeNodes: [{
      type: EFilterRootOption.Channel.toLowerCase(),
      enumValue: EFilterRootOption.Channel,
      label: t( 'labels.channel' ),
      expanded: EFilterRootOption.Channel,
      treeItems: filterOptions.channels,
    },
    {
      type: EFilterRootOption.Application.toLowerCase(),
      enumValue: EFilterRootOption.Application,
      label: t( 'labels.application' ),
      expanded: EFilterRootOption.Channel,
      treeItems: filterOptions.applications
    }],
    availableFilterCodes: availableFilterCodes,
    filteredCodes: filteredCodes,
    initialExpandValue: { channels: true, applications: false },
    filterDataAvailable: filterOptions.applications.length > 0 || filterOptions.channels.length > 0 ? true : false
  }

  /**
   * Trigger on change of filter tree from the left pane
   * @param {IMyConfigurationFilter selectedCodes} is the selected codes from the filtered section
   * @param {string type} is the value like 'Channel | Business | Application'
   * @return void
   */

  const handleFilteredCodes = ( selectedCodes: IMyConfigurationFilter ): void => {
    const { page, limit } = user.myConfigPageDetails;
    setFilteredCodes( selectedCodes );
    fetchMyConfigurations( { page: page, limit: limit, productSearch: configuration.productSearchValue, selectedCodes: selectedCodes } );
  }

  return <Box className="product-portfolio-container">
    <Box className="hierarchy-container" style={ { marginTop: '25px' } }>
      {user.loading ? <div className="loader-placeholder" data-testid="loaderPlaceholder">{t( 'loaderMessage.default' )} ...</div> :
        <SimpleTreeViewComponent filterTreeProps={ filterTreeProps } handleChanges={ handleFilteredCodes } />
      }
    </Box>

    {user.myConfigurations[user.myConfigPageDetails.page]?.length === 0 ? <span className="noConfigurations-label" data-testid="noConfigurationsFound">{t( 'landingPage.noConfigurationsFound' )}</span> :
      <div className="myConfigurations-Tab" >
        <Box className="recordsChange-conatiner">
          <SelectComponent name="recordsPerPage" cssStyle="recordsPerPage" value={ recordsPerPage } options={ RecordsPerPage } onChangeCallBack={ handleChangeForRPP } />
        </Box>
        <Box className="myConfigurations-list-Tab">
          <Grid container spacing={ 1 }>
            {myConfigs.map( ( config: IMyConfiguration, index: number ) =>
              <ConfigurationCardItem key={ config.configurationId } config={ config } index={ index } />
            )}
          </Grid>
        </Box>

        <Stack spacing={ 1 } className="myConfig-pagination">
          <Pagination color="primary" count={ totalMyConfigRecords } page={ myConfigPage } onChange={ handleChange } />
        </Stack>

      </div>
    }

  </Box>
}

export default memo( MyConfigurations );