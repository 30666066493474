import { IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch, AppStore } from '../../store';
import { Build } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { UrlHelper, getSubModelInitialTab } from '../../services';
import { NumericControl } from '..';
import { ISubModelControlProps, IValueType } from '../../../types';
import { SessionStore } from '../../services/SessionStore';
import { setCurrentActiveTab } from '../../store/states/ApplicationSettingsSlice';

/**
 * Renders a numeric input and a configure button
 * @param {ISubModelControlProps} props the properties for the submodel control box
 * @returns {JSX.Element} the submodel control box
 */
export const SubModelControl = ( { disabled, onChange, label, value, variable }: ISubModelControlProps ) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isValid, setIsValid] = useState( true );
  const navigate = useNavigate();

  const configuration = useSelector( ( state: AppState )=> state.configuration )
  
  if ( !configuration ) {
    return null;
  }
  
  const subModelId = configuration.savedConfiguration?.modelContext.subModels.find( m => m.subModelVariableIds?.includes( variable.id ) )?.id;

  if ( !subModelId ) {
    console.warn( `subModel id not found for ${variable.id}` );
    return null;
  }

  const currentSubModel = UrlHelper.getSearchParameter( 'model' );
  const modelPath = currentSubModel ? [...currentSubModel.split( '.' ), subModelId].join( '.' ) : subModelId;
  const searchParams = UrlHelper.addSearchParameter( 'model', modelPath );
 

  const onInternalChange = ( newValue: IValueType ) => onChange && onChange( newValue );
  
  //Navigate to sub model page when click on configure icon
  const onConfigure = () => {
    const productsActiveTab = AppStore.getState().applicationSettings.productsActiveTab;
    const currentActiveTab = productsActiveTab[modelPath] === undefined ? getSubModelInitialTab( configuration, modelPath ) : productsActiveTab[modelPath].tabIndex;   
    dispatch( setCurrentActiveTab( { activeTab: currentActiveTab } ) ); 
    SessionStore.set( 'isGuardedTab', false );
    navigate( `${window.location.pathname}?${searchParams}`, {replace: true} )
  };

  const getSubModelIcon = () => <Tooltip title="Configure" placement="right-start">
    <span>
      <IconButton disabled={ !value || disabled || !isValid } onClick={ onConfigure } className="m-0 submodelIcon">
        <Build color={ !value || disabled || !isValid ? 'disabled' : 'primary' } fontSize="medium" />
      </IconButton>
    </span>
  </Tooltip>

  return <NumericControl 
    variable={ variable } 
    label={ label } value={ value } 
    onCallback={ setIsValid } 
    subModelIcon={ () => getSubModelIcon() }
    disabled={ disabled }
    onChange={ onInternalChange }
  />
}