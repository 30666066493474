import { getPackagePath, getProductId } from '../services';
import { ApiUrl } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';
import { IAuthorizationRequest, IModelRequest, IProductCatalogRequest, IPropertyAssociationRequest, ISubModelRequest } from '../../types/IApiTypes';

export const ProductApi = {
  productCatalog: async ( payload: IProductCatalogRequest ) => {
    const { token, page, limit, productSearch, agCodes, channelCodes } = payload;
    let parmas = { Page: page, Limit: limit };
    if ( productSearch ) {
      const search = { search: productSearch }
      parmas = { ...search, ...parmas };
    }

    try {
      const response = await AxiosClient
        .post( '/product/v1/catalog',
          {
            agCodes: agCodes ? agCodes : [],
            channels: channelCodes ? channelCodes : []
          },
          { params: parmas, headers: getHeaders( token )?.headers }
        );
      const total = JSON.parse( response.headers['x-pagination'] ).Total;
      return {
        productCatalog: response.data,
        pageDetails: { page: page, totalRecords: total, limit: limit }
      };
    } catch {
      return null;
    }
  },


  getProductImages: ( action: IModelRequest ) => {
    return AxiosClient
      .get( ApiUrl.ImagesApi,
        {
          params: { packagePath: getPackagePath( getProductId() ), productModel: action.modelId },
          headers: getHeaders( action.token ).headers
        },
      ).then( ( response ) => {
        return {
          productImages: response.data,
          modelId: action.modelId
        }
      } ).catch( () => {
        return {
          modelId: action.modelId
        }
      } )
  },

  productFilter: async ( payload: IAuthorizationRequest ) => {
    try {
      const response = await AxiosClient
        .get( '/product/v1/filter/read',
          getHeaders( payload.token )
        );
      return {
        productFilterOptions: response.data
      };
    } catch {
      return [];
    }
  },

  //Get Property Associations from DB using DIS API
  getPropertyAssociations: async ( payload: IPropertyAssociationRequest ) => {
    const req = {
      packageId: payload.packageId,
      productId: payload.productId
    }
    try {
      const response = await AxiosClient
        .post( '/product/v1/propertyassociation/read', req, getHeaders( payload.token )
        );
      return {
        propertiesAssociation: response.data.propertyAssociations,
        propertyDefinition: response.data.definitions ?? payload.propertyDefiniton,
        modelId: payload.productId
      };
    } catch {
      return {
        data: [{
          modelId: payload.productId,
          subModelId: payload.productId
        }]
      };
    }
  },

  //Get the submodel count variable data
  getSubModelData: async ( payload: ISubModelRequest ) => {
    const req = {
      productId: payload.modelId,
      packageId: payload.modelId
    }
    try {
      const response = await AxiosClient
        .post( '/product/v1/data/read', req, getHeaders( payload.token )
        );
      return {
        subModelData: response.data,
        modelId: payload.modelId
      };
    } catch ( err ) {
      return Promise.reject( new Error( err ) );
    }
  },

}