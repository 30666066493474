import React from 'react';
import { Box} from '@mui/material';
import { IPriceProps } from '../../../types';
import { getFormattedPrice,getSymbol } from '../../services';
import { isFeatureVisible } from '../../services/ClaimsHelper';
import { isValidCurrency } from '../../services/Price';
import { useSelector } from 'react-redux';
import { AppState, AppStore } from '../../store';

export const Price = ( {price,isBundled}:IPriceProps ) => {
  const featureFlags = AppStore.getState().claimsData?.featureFlags;
  const currency = useSelector( ( state: AppState )=> state.applicationSettings.currency )

  const { countryCode, currencyCode } = currency;

  if( price === null && !isBundled ) {
    return null
  }
  if( !isFeatureVisible( featureFlags.ListPrice ) ) {
    return null
  }
  return <Box alignItems="center" justifyContent="center" >
    {currencyCode && <span className={ !isValidCurrency( currencyCode ) ? 'invalidCurrency' : 'content' }>
      { getSymbol( countryCode, currencyCode ) } 
    </span>}
    {currencyCode && getFormattedPrice( price )}
  </Box>
}