import { IClaims, IClaimsData, IFeatureFlag, IFeatureFlags } from '../../types';

export const isFeatureActive = ( featureFlag:IFeatureFlag )=> featureFlag.active;
export const isFeatureVisible = ( featureFlag:IFeatureFlag )=> featureFlag.visible;
export const isFeatureEditable = ( featureFlag:IFeatureFlag )=> featureFlag.editable;


export function getFeatureFlags( action:{ claims: IClaims }, state: IClaimsData ):IFeatureFlags {
  const featureFlags: IFeatureFlags = { ...state.featureFlags };
  
  Object.keys( featureFlags ).forEach( ( featureFlagName:string )=>{
    const featureFlag:IFeatureFlag = action?.claims?.featureFlags.find( ( flag:IFeatureFlag ) => flag.name === featureFlagName );
    if( featureFlag ) {
      featureFlags[featureFlagName] = { ...featureFlag, active: true, visible:true }
    }
  } )
  return featureFlags;
}