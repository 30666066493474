import React from 'react'
import { FormControl, MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ISelectComponent } from '../../../types'

/**
 * 
 * @prop {name} is the name of the element
 * @prop {value} is the value of the element
 * @prop {cssStyle} is the class names
 * @prop {options} is the option list for selection
 * @callback {onChangeCallBack} call back on selction change
 * @returns {JSX.Element} a FormControl of Slect component.
 */

export const SelectComponent = ( { name, value, cssStyle, options, onChangeCallBack }: ISelectComponent ) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl size="small" style={ { width: '4.5rem', marginLeft: '5px' } }>
        <Select
          id={ name }
          name={ name }
          value={ value }
          onChange={ ( e ) => onChangeCallBack( e ) }
          className={ cssStyle }
        >
          {options.map( ( option: string | number ) => <MenuItem key={ option } value={ option }>{option}</MenuItem> )}
        </Select>
      </FormControl>
      <label>{t( 'labels.records' )} :</label>
    </>

  )
}