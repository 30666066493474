import { Grid } from '@mui/material';
import React from 'react';
import { isScope } from '../services';
import { VariableInput } from '.';
import { IConfigurationVariable, ISectionProps } from '../../types';
import { gridLayout } from './SectionLayout';
import { ESectionId } from '../data/Constants';

const getGridData = ( section ) => {
  const isAllItemHdeOrSuppressed = section.variables.filter( ( variable:IConfigurationVariable ) => {
    return variable.suppressed || variable.hide;
  } )
  if( isAllItemHdeOrSuppressed.length === section.variables.length ) {
    return null
  }
  return <Grid container item xs={ gridLayout.fullWidth } className={ 'height-fit-content' }>
    <h6 className="title" >{section.id === ESectionId.Guarded ? 'Guarded Variables' : section.name }</h6>
  </Grid>
}

/**
 * Handles subsections and variables.
 * Renders first variables of the current section, then subsections.
 * @param {ISectionProps} props the properties for the section component
 * @returns {JSX.Element} the section component
 */
export const Section = ( { onChange, section, onOptionalChange }: ISectionProps ) => { 
  return <Grid container item xs={ gridLayout.fullWidth } className={ 'sectionStyle height-fit-content' }>
    {getGridData( section )}
    {section.variables?.map( ( v ) => <Grid container item xs={ gridLayout.fullWidth } key={ `variable-${v.id}` } className={ 'height-fit-content' }><VariableInput onChange={ onChange } variable={ v } onOptionalChange={ onOptionalChange } /></Grid> ) }
    {section.sections?.filter( ( sec ) => !isScope( sec ) ).map( ( s ) => <Section key={ `section-${s.id}` } onChange={ onChange } section={ s } onOptionalChange={ onOptionalChange }/> ) }
  </Grid>;
}