import { NaNCurrencyCode } from '../data/Constants';

/**
 * Checks if the given currency is a Valid Currency
 * @param {string | null | undefined} currency
 * @returns {boolean} returns false when currency is NaN or null else returns true
 */
export function isValidCurrency( currency: string | null | undefined ): boolean {
  if ( currency && currency !== NaNCurrencyCode ) {
    return true
  } else {
    return false
  }
}