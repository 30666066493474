import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { ESessionStore, ETabValue } from '../../data/Constants';
import { IExtendedConfigureResponse, IImagesData, IPriceData, ISalesText, IMaterialPropertyAssociations, ISubModelData } from '../../../types';
import { ConfigurationApi } from '../../api/ConfigurationApi';
import { SessionStore } from '../../services/SessionStore';
import { configured, getErrorData, getPriceData, loadedConfiguration, setProductImages, setPropertyAssociations, setSalesText } from '../Helpers/ConfigurationSliceHelper';
import { SalesTextApi } from '../../api/SalesTextApi';
import { PriceApi } from '../../api/PriceApi';
import { NotifyAPI } from '../../api/NotifyAPI';
import { ProductApi } from '../../api/ProductApi';
import { checkMandatoryProperties, updateProductORIProperties, updateProperties, checkIsConfigitMandatoryPropertiesExist } from '../../services/ConfigurationDataHelperFunctions';
import { enableMapSet } from 'immer';
import { t } from 'i18next';
import { IClearConfigRequest, IAuthorizationRequest, IConfigurationIdRequest, ICreateConfigurationRequest, ICurrencyRequest, ILoadConfigurationRequest, IPriceRequest, IPropertyAssociationRequest, ISalesTextRequest, IOptionalRequest, IModelRequest, ISubModelRequest } from '../../../types/IApiTypes';

enableMapSet();

const initialState = {
  data: null,
  assignments: [],
  configurationId: null,
  languageCode: null,
  viewIds: null,
  lastChange: null,
  savedConfiguration: null,
  conflict: null,
  invalidView: null,
  isInvalid: null, //to check if configuration is invalid on first configure call
  errorCode: null,
  acceptedChanges: null,
  longSalesText: new Map<string, Map<string, ISalesText>>(),
  pmLanguages: null,
  authState: 'initial',
  contextData: null,
  price: new Map<string, Map<string, IPriceData>>(),
  currencyList: [],
  bundledFeatures: {},
  shortSalesText: new Map<string, Map<string, ISalesText>>(),
  productSearchValue:'',
  onChangeProductSearchValue:'',
  loaderMessage: null,
  configureApiCall: false,
  productImages: new Map<string, Map<string, IImagesData>> (),
  access:'',
  onLoadCheck: false,
  checkLandingTab:{isTabChanged:false,tabValue:ETabValue.Product},
  orderingInstructions:new Map<string, string>(),
  propertyAssociations:new Map<string, Map<string, IMaterialPropertyAssociations>>(),
  isDataSet: true,
  propertyDefinition: [],
  propertyDefinitionMissingError: null,
  configitPropertyInEffect: true,
  subModelData:new Map<string,ISubModelData[]>()

} as unknown as IExtendedConfigureResponse

//Gets the views available for configuration id
export const getViewList = createAsyncThunk( 'configuration/getViewList', async ( action:IConfigurationIdRequest )=>{
  return ConfigurationApi.getViewList( action )
} );

//Loads the Configuration
export const loadConfiguration = createAsyncThunk( 'configuration/loadConfiguration', async ( action:ILoadConfigurationRequest, thunkAPI )=>{
  thunkAPI.dispatch( loadConfig( action ) )
  return ConfigurationApi.loadConfiguration( action );
} );

//Configures the assignments and returns the Configure response
export const configure = createAsyncThunk( 'configuration/configure', async ( action:ICreateConfigurationRequest, thunkAPI )=>{
  thunkAPI.dispatch( callConfigure( action ) )
  return ConfigurationApi.configure( action );
} );

//Returns the sales text based on apitype - short or long
export const getSalesText = createAsyncThunk( 'configuration/getSalesText', async ( action:ISalesTextRequest )=>{
  return SalesTextApi.salesText( action );
} );

//Return the languages available for a product model
export const getPMLanguages = createAsyncThunk( 'configuration/getPMLanguages', async ( action:IConfigurationIdRequest )=>{
  return ConfigurationApi.getPMLanguages( action );
} );

//Returns the prices of Product model
export const getPrice = createAsyncThunk( 'configuration/getPrice', async ( action:IPriceRequest )=>{
  return PriceApi.price( action )
} );

//Returns the currencies available for the market given for configuration
export const getCurrencyList = createAsyncThunk( 'configuration/getCurrencyList', async ( action:ICurrencyRequest )=>{
  return PriceApi.currency( action )
} );

//Clears the configuration 
export const clearConfiguration = createAsyncThunk( 'configuration/clearConfiguration', async ( action:IClearConfigRequest )=>{
  return ConfigurationApi.clearConfiguration( action )
} );

//Returns the price list map for the selected country/market for configuration
export const getPriceListMap = createAsyncThunk( 'configuration/getPriceListMap', async ( action:IAuthorizationRequest )=>{
  return PriceApi.priceListMap( action )
} );

//Creates a configuration
export const createConfiguration = createAsyncThunk( 'configuration/createConfiguration', async ( action:ICreateConfigurationRequest )=>{
  return ConfigurationApi.createConfiguration( action )
} );

//Notify which is save and close
export const notify = createAsyncThunk( 'configuration/notify', async ( action:IConfigurationIdRequest )=>{
  return NotifyAPI.notify( action )
} );

//Gets all the available images for the product model
export const getProductImages = createAsyncThunk( 'configuration/getProductImages', async ( action:IModelRequest )=>{
  return ProductApi.getProductImages( action )
} );

//sets the optional items to db
export const setOptionalItems = createAsyncThunk( 'configuration/setOptionalItems', async ( action:IOptionalRequest )=>{
  return ConfigurationApi.setOptionalItems( action )
} );

//gets the property associations
export const getPropertyAssociations = createAsyncThunk( 'configuration/getPropertyAssociations', async ( action:IPropertyAssociationRequest )=>{
  return ProductApi.getPropertyAssociations( action )
} );

//gets the submodel countvariable data
export const getSubModelData = createAsyncThunk( 'configuration/getSubModelData', async ( action:ISubModelRequest )=>{
  return ProductApi.getSubModelData( action )
} );

const configurationSlice = createSlice( {
  name: 'configuration',
  initialState,
  reducers: {
    loadConfig( state, action ) {
      state.configurationId = action.payload.configurationId
      state.languageCode = action.payload.languageCode 
      state.isInvalid = null
      state.acceptedChanges = null
    },
    callConfigure( state, action ) {
      state.assignments = action.payload.request?.configureRequest?.line.variableAssignments;
      state.lastChange = action.payload.lastChange || null
    },
    clearConflict( state ) {
      state.conflict = null
    },
    validViewId( state ) {
      state.invalidView = false
    },
    setAuthState( state, action ) {
      state.authState = action.payload.authState
    },
    resetModelPrice( state ) {
      state.price = initialState.price
    },
    clearOptionalItem( state ) {
      if ( state.savedConfiguration ) {
        state.savedConfiguration.optionalItems = new Map<string, boolean>()
      }
    },
    onLoadCheck( state, action ) {
      state.onLoadCheck = action.payload.key
    },
    setConfiguration( state, action ) { //Sets the updated configuration which includes properties to state
      state.isDataSet = false
      state.data = action.payload.data
      if( state.savedConfiguration ) {
        state.savedConfiguration.configureResponse = action.payload.data
      }else{
        state.savedConfiguration = {
          configureResponse: action.payload.data
        }
      }
      state.contextData = action.payload.contextData
    },
    setLoaderMessage( state, action ) {
      state.loaderMessage = action.payload
    },
    setConfigureAPI( state, action ) {
      state.configureApiCall = action.payload.value
    },
    resetConfiguration() {
      return initialState
    },
    updateProductSearchValue( state, action ) {
      state.productSearchValue = action.payload.productSearch
    },
    onChangeProductSearchValue( state, action ) {
      state.onChangeProductSearchValue = action.payload.productSearch
    },
    changeLandingTab( state, action ) {
      state.checkLandingTab = {
        isTabChanged: action.payload.value,
        tabValue: action.payload.tabValue
      }
    }, 
    setOptionalData( state, action ) {
      state.savedConfiguration.optionalItems = action.payload.optionalItems
    }
  },
  extraReducers: ( builder ) =>{
    builder.addCase( getViewList.fulfilled, ( state, action ) => {
      if( action.payload.data ) {
        SessionStore.set( ESessionStore.ViewId, action.payload.data.length > 0 && action.payload.data[0] )
        state.viewIds = action.payload.data
      }
    } );
    builder.addCase( loadConfiguration.fulfilled, ( state, action ) => {
      if( action.payload?.data ) {
        const assigndData = loadedConfiguration( action.payload,state );
        state.data = assigndData.data
        state.assignments = assigndData.assignments
        state.access = assigndData.access
        state.contextData = assigndData.contextData
        state.conflict = assigndData.conflict
        state.isInvalid = assigndData.isInvalid
        state.acceptedChanges = assigndData.acceptedChanges
        state.savedConfiguration = assigndData.savedConfiguration
        state.isDataSet = true
        state.configitPropertyInEffect = checkIsConfigitMandatoryPropertiesExist( assigndData.data.sections[0].variables[0].properties )
      }
    } );
    builder.addCase( getSalesText.fulfilled, ( state, action ) => {
      state.isDataSet = true
      setSalesText( action.payload, state );
    } );

    builder.addCase( getSalesText.rejected, ( state, action ) => {
      const params = action.meta.arg;
      if( params.apiType === 'short' ) {
        state.shortSalesText?.set( params.modelId, new Map<string,ISalesText> () )
      }
    } );
    builder.addCase( getPMLanguages.fulfilled, ( state, action ) => {
      if( action.payload?.pmLanguages ) {
        state.pmLanguages = action.payload?.pmLanguages[0].languages;
      }
    } );
    builder.addCase( getPrice.fulfilled, ( state, action ) => {
      if( action.payload?.price ) {
        getPriceData( action.payload, state )
        state.isDataSet = true;
      }
    } );
    builder.addCase( getCurrencyList.fulfilled, ( state, action ) => {
      if( action.payload?.currency ) {
        state.currencyList = action.payload.currency
      }
    } );
    builder.addCase( getProductImages.fulfilled, ( state, action ) => {
      setProductImages( action.payload, state )
    } ); 
    builder.addCase( getProductImages.rejected, ( state, action ) => {
      const params = action.meta.arg;
      state.productImages?.set( params.modelId, new Map<string,IImagesData> () );
    } );
    builder.addCase( getPropertyAssociations.fulfilled, ( state, action ) => {
      
      if( action.payload?.propertiesAssociation ) {
        const {modelId, propertyDefinition } = action.payload;
        setPropertyAssociations( action.payload, state )

        state.propertyDefinition = propertyDefinition.length > 0 ? propertyDefinition : state.propertyDefinition;
        const pdmPropertiesExist: boolean = checkMandatoryProperties( state.propertyDefinition );
        updateProductORIProperties( state, modelId );
        
        if( pdmPropertiesExist ) {
          state.data = updateProperties( state.data, state.propertyAssociations, state.propertyDefinition )
          state.propertyDefinitionMissingError = null;
        } else {
          state.propertyDefinitionMissingError = getErrorData( t );
        }
      } else if ( action.payload?.data[0]['modelId'] === action.payload?.data[0]['subModelId'] ) {        
        state.propertyDefinitionMissingError = getErrorData( t );
      }
    } );
    builder.addCase( getSubModelData.fulfilled, ( state, action ) => {
      state.subModelData?.set( action.payload.modelId, action.payload.subModelData.subModelData )
    } ); 

    builder.addCase( getSubModelData.rejected, ( state, action ) => {
      const params = action.meta.arg;
      state.subModelData?.set( params.modelId, [] );
    } );

    builder.addMatcher( isAnyOf( configure.fulfilled, clearConfiguration.fulfilled ), ( state, action ) => {
      if( action.payload?.configuration ) {
        state.isDataSet = true;
        const assigndData = configured( action.payload, state )
        state.conflict = assigndData?.conflict
        state.data = assigndData?.data
        state.assignments = assigndData?.assignments
        state.isInvalid = assigndData?.isInvalid === false || assigndData?.isInvalid ? assigndData.isInvalid : null
        state.acceptedChanges = assigndData?.acceptedChanges
        state.contextData = assigndData?.contextData
      }
    } );
  }
} )

export const { loadConfig, callConfigure, clearConflict, validViewId, setAuthState, resetModelPrice,
  clearOptionalItem, onLoadCheck, setLoaderMessage, setConfigureAPI, resetConfiguration,
  updateProductSearchValue, onChangeProductSearchValue, changeLandingTab, setConfiguration, setOptionalData
} = configurationSlice.actions;


export default configurationSlice.reducer
