import { Info } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { IInfoDialogIconProps } from '../../../types';
import { InfoDialog } from '../InfoDialog';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { getSubmodelORI } from '../../services/ConfigurationDataHelperFunctions';


/**
   * Renders the Info Dialog .
   * @param {IInfoDialogIconProps} props containds the variable and type
   * @returns {JSX.Element} renders the long sales text, ORI Property and images for the variables 
*/

export const InfoDialogIcon = ( {variable,type}:IInfoDialogIconProps ) => {
  const [openDialog, setOpenDialog] = useState<boolean>( false );

  const configuration = useSelector( ( state:AppState ) => state.configuration );
  
  const getClassName = ()=>{
    let iconClass = ''
    let iconClassHighlight = ''
    iconClass = type && type === 'FF' ? 'familyInfoDialogIcon' : 'infoDialogIcon';
    const hasHighLight = variable?.pdmProperties?.orderingInstructions || variable?.hasORI || getSubmodelORI( configuration, variable );
    iconClassHighlight = hasHighLight ? 'infoDialogIconHighlight' : '';
    return iconClass + ' ' + iconClassHighlight
  }

  const handleCloseDialog = ()=> {
    setOpenDialog( false )
  }
  
  return <><IconButton 
    data-testid="btn-info-dialog" 
    onClick={ () => {
      setOpenDialog( true )
    } } className={ `${getClassName( )} ` }
  >
    <Info color="primary" />
  </IconButton>
  {openDialog && <InfoDialog variable={ variable } handleCloseDialog={ handleCloseDialog }/> }
  </> || null;
}