import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import React, { useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getConflictsList, getNameWithCode } from '../services';
import { ICollapsibleConflictTableType, IConflictTableProps, IConflictInnerTableType, IFeatureOrFamily, ICollapsibleConflictRowType } from '../../types';
import { useTranslation } from 'react-i18next';
/**
 * returns a table showing the feature description and the values conflicting for that feature
 * @param {IConflictInnerTableType} param0 The properties required for constructing and displaying the Table
 * @returns {JSX.Element} The Table displaying Feature description and conflicting values of those features
 */
function CollapsibleInnerTable( {model,showCode}:Readonly<IConflictInnerTableType> ) {
  const {t} = useTranslation();

  if( !model ) {
    return null;
  }
  const connectingStrings = ['labels.from','conflictDialog.conflictTable.removedFrom'];
  return <TableContainer className="conflictTableInner-root mx-auto">
    <Table size="small" className="mx-auto"> 
      <TableBody className="subRowText">
        {
          model.isModelConflict && <TableRow>
            <TableCell className="tabCell subRowText text-left vertical-middle justify-left align-center" align="left">
              <Box className= "modelConflict-icon">
                <WarningIcon fontSize="small"/>
              </Box>
              {t( 'conflictDialog.conflictTable.modelConflictMessage' )} <b>
                {getNameWithCode( {name:model.name,id:model.id,shortSalesText:model.shortSalesText} as IFeatureOrFamily,showCode )}
              </b>
            </TableCell>
          </TableRow>
        }
        {model.sections.map( section => 
          <React.Fragment key={ `${model.id}.${section.id}` }>
            <TableRow className="bg-lightgray">
              <TableCell className="tabHead rowText" colSpan={ 2 } variant="head">{getNameWithCode( section as IFeatureOrFamily,false )}</TableCell>
            </TableRow>
            {section.conflicts.map( variable => <TableRow className="p-0" key={ `${model.id}.${section.id}.${variable.id}` }>
              <TableCell className="tabCell subRowText w-90" align="left">{variable.values?.length > 0 && getConflictsList( variable,showCode )?.map( ( conflict:string,idx:number ) => <Box key={ section.id + '.conflicts.' + idx }><b>{conflict}</b> {t( connectingStrings[idx] )} <b>{getNameWithCode( {id:variable.id,name:variable.name,shortSalesText:variable.shortSalesText} as IFeatureOrFamily,showCode )}</b></Box> )}</TableCell>
              <TableCell className="tabCell w-10" align="left"/>
            </TableRow> )}
          </React.Fragment>
        )}
      </TableBody>
    </Table>
  </TableContainer> 
}
      
/**
 * Creates Row for a model as required for the collapsible table component
 * @param {ICollapsibleConflictRowType} props various properties that are required for generation of the row
 * @returns {JSX.Element} a row represnting a collapsible table for the given model based on the properties
 */
function CollapsibleTableRow( { model,expand,showCode }:Readonly<ICollapsibleConflictRowType> ) {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState( false );

  useEffect( ()=>{
    setOpen( expand )
  },[expand] );

  if( !model ) {
    return null;
  }

  const iconClick = ( e:React.MouseEvent<HTMLDivElement> )=>{
    e.preventDefault();
    e.stopPropagation();
  }
 
  return (
    <>
      <TableRow className="rowText" onClick={ ()=>setOpen( !open ) }>
        <TableCell className="tabCell rowText w-auto" component="th" scope="row" align="left">
          {getNameWithCode( {name:model.desc,id:model.id,shortSalesText:model.shortSalesText} as IFeatureOrFamily,showCode )}
        </TableCell>
        <TableCell className="tabCell mw-20" component="th" scope="row" align="right">
          {model.isModelConflict && <Tooltip title={ <>{t( 'conflictDialog.conflictTable.modelConflictMessage' )}
            <b>{getNameWithCode( {name:model.name,id:model.id,shortSalesText:model.shortSalesText} as IFeatureOrFamily,showCode )}</b></> }
          >
            <Box className="warning d-inline-flex mw-50 align-center vertical-middle justify-center text-right" onClick= { ( e:React.MouseEvent<HTMLDivElement> )=>iconClick( e ) }>
              <WarningIcon fontSize="small"/>
            </Box>
          </Tooltip>}
          <Tooltip title={ open ? t( 'tooltip.collapse' ) : t( 'tooltip.expand' ) }>
            <IconButton
              aria-label={ `${t( 'tooltip.collapse' )}/${t( 'tooltip.expand' )}` }
              size="small"
              onClick={ () => setOpen( !open ) }
              className="mw-50 align-center vertical-middle justify-center text-right"
            >
              {open ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small"/>}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow className="collapsibleTableRow">
        <TableCell className="pb-0 pt-0 bottomUnset" colSpan={ 2 }>
          <Collapse in={ open } timeout="auto" unmountOnExit>
            <CollapsibleInnerTable model={ model } showCode={ showCode }/>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
       
/**
 * Creates Collapsible table as required and defined by the properties
 * @param {ICollapsibleConflictTableType} param0 various properties that are required for generation of table
 * @returns {JSX.Element} a Collapsible Table representing all the conflicts that occur
 */
function CollapsibleTable ( {models,showCode}:Readonly<ICollapsibleConflictTableType> ) {
  const {t} = useTranslation();
  const [open,setOpen] = React.useState<boolean>( false );

  if( !models ) {
    return <Box className="d-flex justify-center align-center h-100">{t( 'conflictDialog.conflictTable.noConflicts' )}</Box>;
  }
  return <TableContainer className="collapsibleTable-root overflow-auto">
    <Table stickyHeader aria-label="collapsible table">
      <TableHead className="m-0 p-0">
        <TableRow>
          <TableCell className="tabCell subTableHeader w-90" variant="head" align="left">
            { t( 'conflictDialog.conflictTable.conflictsWith' )}
          </TableCell>
          <TableCell className="tabCell w-10" variant="head" align="right">
            <Tooltip title={ open ? t( 'tooltip.collapseAll' ) : t( 'tooltip.expandAll' ) }>
              <IconButton
                aria-label={ `${t( 'tooltip.collapseAll' )}/${t( 'tooltip.expandAll' )}` }
                size="medium"
                onClick={ () => setOpen( !open ) }
                className="vertical-middle text-right"
                data-testid ="collapse-button"
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {models?.map( ( model ) => 
          <CollapsibleTableRow key={ model.id } model={ model } showCode={ showCode } expand={ open }/>
        )}
      </TableBody>
    </Table>
  </TableContainer>
}
      
/**
 * A container to render the Conflicts
 * @param {IConflictTableProps} param0 properties for the ConflictTable component 
 * @returns {JSX.Element} The component displaying the Conflicts
 */
export const ConflictTable = ( {modelRows,showCode}:IConflictTableProps ) => {
  return <Box><CollapsibleTable models={ modelRows } showCode={ showCode }/></Box>
}