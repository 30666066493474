import { Box, Button, IconButton, Tooltip, Autocomplete, TextField, InputAdornment, Popper } from '@mui/material';
import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotifyAPI } from '../api/NotifyAPI';
import { SessionStore } from '../services/SessionStore';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch } from '../store';
import { UrlHelper } from '../services/UrlHelper';
import { getLanguageCode, getViewId, GetToken, isSubModelAssigned } from '../services';
import { useTranslation } from 'react-i18next';
import { ViewHandler } from './ViewHandler';
import { LanguageSetting } from './LanguageSetting';
import { IApplicationSettings, IExtendedConfigureResponse, IProductSearchBarProps, ISearchOption, IUpdateSearchProps } from '../../types';
import { CurrencySetting } from './CurrencySetting';
import { EConfigurationType, EActionType, PopperModifier, ESessionStore, EUrlParams, ETabValue, EPageNames } from '../data/Constants';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SearchIcon from '@mui/icons-material/Search';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { AppAuthContext } from '../services/Contexts';
import { SaveAndClose } from './SaveAndClose';
import { MessagesPopover } from './MessagesPopover';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';
import { setUserSettings } from '../store/states/UserSettingsSlice';
import { setCurrentActiveTab, setIsConfigurationChanged } from '../store/states/ApplicationSettingsSlice';
import { getProductCatalog } from '../store/states/ProductCatalogSlice';
import { setError } from '../store/states/ErrorSlice';
import { changeLandingTab, clearConfiguration, clearOptionalItem, loadConfiguration, onChangeProductSearchValue, setLoaderMessage, updateProductSearchValue } from '../store/states/ConfigurationSlice';
import ActionDialog from './ActionDialog';
import { getMyConfigurations } from '../store/states/UserSlice';

const displayCodeIcon = ( showCode: boolean ) => {
  if ( showCode ) {
    return <VisibilityIcon fontSize="medium" />
  } else {
    return <VisibilityOffIcon fontSize="medium" />
  }
}

const displayResetIcon = ( disableButton: boolean ) => {
  if ( disableButton ) {
    return <img className="rest-icon" src="../../public/svg/eraser_disabled.svg" alt="reset-disable" />
  } else {
    return <img className="rest-icon" src="../../public/svg/eraser.svg" alt="reset-enable" />
  }
}

const disableResetIcon = ( configuration: IExtendedConfigureResponse, applicationSettings: IApplicationSettings ) => {
  return configuration.savedConfiguration?.state === EConfigurationType.Temp || configuration.savedConfiguration?.state === EConfigurationType.ReadOnly ? true : !applicationSettings.isConfigurationChanged;
}

function disableClearAllBtn( configuration: IExtendedConfigureResponse ) {
  return configuration.savedConfiguration?.state === EConfigurationType.Temp || configuration.savedConfiguration?.state === EConfigurationType.ReadOnly || configuration.access === EConfigurationType.ReadOnly
}

// Display product list in AutoComplete when length of input text is greater than 2
const searchProduct = ( e: any, dispatch: AppDispatch ) => {
  if ( e.target.value && ( e.target.value.length > 2 || e.target.value.length === 0 ) ) {
    dispatch( onChangeProductSearchValue( { productSearch: e.target.value } ) )
  }
}

const updateProductCatalog = ( newVal: string | ISearchOption, props:IUpdateSearchProps ):void => {
  const { token, configuration, setSearchValue, location, productSettings, searchValue, user, dispatch } = props
  let searchKey = newVal ?? '';

  const displayName = searchKey?.displayName ? searchKey?.displayName : searchKey;
  searchKey = typeof searchKey === 'string' ? searchKey : searchKey.title;

  setSearchValue( displayName );

  dispatch( updateProductSearchValue( { productSearch: searchKey } ) );
  dispatch( onChangeProductSearchValue( { productSearch: searchKey } ) )
  if ( location.pathname === EPageNames.LandingPage && configuration.checkLandingTab.tabValue === ETabValue.Product && ( searchKey || searchValue ) ) {
    dispatch( getProductCatalog( { token: token, page: 1, limit: productSettings.productCatalogDetails.limit, productSearch: searchKey, agCodes: productSettings.productCatalogDetails.agCodes, channelCodes:  productSettings.productCatalogDetails.channelCodes} ) )
  } else if ( location.pathname === EPageNames.LandingPage && configuration.checkLandingTab.tabValue === ETabValue.MyConfiguration && ( searchKey || searchValue ) ) {
    const { channelCodes, applicationIds, limit } = user.myConfigPageDetails; 
    dispatch( getMyConfigurations( { token: token, page: 1, limit: limit, productSearch: searchKey, applicationIds: applicationIds, channelCodes: channelCodes } ) )
  }
}

const CloseWithoutSaveDialog = ( { onCloseWithoutSavingDialog, t }: any ) => {
  return !IsDefaultFlowInSessionStore() ? <Tooltip title={ t( 'tooltip.closeWithoutSaving' ) }>
    <span>
      <IconButton
        className={ 'action-panel-buttons icon-font-size closeWithoutSave-icon' }
        onClick={ onCloseWithoutSavingDialog }
        data-testid="btn-close-configuration"
      >
        <DisabledByDefaultRoundedIcon />
      </IconButton>
    </span>
  </Tooltip> : <></>
}

const handleError = ( err: any, setErrorMessage: ( message: string ) => void, onCloseDialog: () => void, t: ( message: string ) => void, dispatch: AppDispatch ) => {
  if ( err.response ) {
    dispatch( setError( {
      code: err.response.status ? err.response.status : 500,
      message: err.response.data ? err.response.data.Message : err.message,
      page: 'notify'
    } ) )
    setErrorMessage( err.response.data ? err.response.data.Message : err.message + '. ' + t( 'errorMessages.tryAgain' ) )
    onCloseDialog();
  }
  dispatch( setLoaderMessage( null ) );
}

const CustomPopper = function ( props: any ) {
  return <Popper { ...props } placement="bottom" modifiers={ PopperModifier } />;
};

const onSearchValueChange = ( value, reason, dispatch: AppDispatch ) => {
  if ( value === '' && reason === 'reset' ) {
    dispatch( onChangeProductSearchValue( { productSearch: value } ) )
  }
}

//This function returns value to show in the search text box  
//when matching criteria is found then option.displayName or option.title is returned
//when no option matching the search criteria is found then searchValue is returned as a fallback
function getSearchOption( option: ISearchOption, searchValue: string ): string {
  if ( option.displayName ) {
    return option.displayName;
  }
  return option.title ? option.title : searchValue;
}

//Search Field
const searchBox = ( params, dispatch: AppDispatch, t ) => {
  return <TextField { ...params } className="search-inner-text" placeholder={ t( 'labels.search' ) } onChange={ ( e ) => searchProduct( e, dispatch ) } InputProps={ {
    ...params.InputProps,
    startAdornment: <InputAdornment position="end" className="search-icon"> <SearchIcon />
    </InputAdornment>
  } }
  />
}

const ProductSearchBar = ( ProductSearchBarProps: IProductSearchBarProps ) => {
  const { searchValue, setSearchValue, searchMatches, searchCount } = ProductSearchBarProps
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { token } = GetToken();

  const user = useSelector( ( state: AppState ) => state.user );
  const productSettings = useSelector( ( state: AppState ) => state.productCatalog );
  const configuration = useSelector( ( state: AppState ) => state.configuration )
 
  if ( location.pathname === EPageNames.LandingPage ) {
    return <Autocomplete
      id="product-search-box"
      clearOnBlur
      freeSolo
      options={ [] }
      className="search-bar"
      data-testid="search-bar"
      value={ searchValue }
      renderInput={ ( params ) => searchBox( params, dispatch, t ) }
      onChange={ ( _, newVal ) => updateProductCatalog( newVal, { token, configuration, setSearchValue, location, productSettings, searchValue, dispatch, user } ) }
      PopperComponent={ CustomPopper }
    />
  } else {
    const options = searchMatches.map( ( option: ISearchOption ) => {
      const familyName = option.familyName.toUpperCase();
      return {
        ...option,
        familyName: familyName,
      };
    } );
    return <>{searchValue.length > 0 ? <span className="result-found" > {t( 'labels.result' )}: {searchCount}</span> : null}<Autocomplete
      id="product-search-box"
      clearOnBlur={ !searchValue }
      onInputChange={ ( _e, value, reason ) => {
        onSearchValueChange( value, reason, dispatch );
      } }
      freeSolo
      options={ options.sort( ( a, b ) => -b.familyName.localeCompare( a.familyName ) ) }
      groupBy={ ( option ) => option.familyName }
      getOptionLabel={ ( option ) => getSearchOption( option, searchValue ) }
      className="search-bar"
      value={ searchValue }
      renderInput={ ( params ) => searchBox( params, dispatch, t ) }
      renderGroup={ ( params ) =>
        <div key={ params.group }>
          <span className="display-options-header"> {params.group ? <><span>&nbsp;</span> {params.group}</> : ''}</span>
          {params.children}
        </div>
      }
      onChange={ ( _, newVal ) => updateProductCatalog( newVal, { token, configuration, setSearchValue, location, productSettings, dispatch } ) }
      PopperComponent={ CustomPopper }
    /></>

  }
}

const closeTab = ( dialogType: string, onCloseDialog: () => void, navigate: ( path: string, replace: object ) => void, configurationId: string | null, auth ) => {
  onCloseDialog();
  if ( dialogType === EActionType.Notify ) {
    navigate( '/notified', { replace: true } );
  } else {
    navigate( '/closeTab', { replace: true } );
    window.parent.postMessage( { message: 'configuration_close', cid: configurationId }, document.referrer );
  }
  setTimeout( () => {
    auth.signOut();
    SessionStore.clear()
  }, 2000 )
}

const tabChanged = ( configuration, setSearchValue, dispatch: AppDispatch ) => {
  if ( configuration.checkLandingTab.isTabChanged ) {
    setSearchValue( '' );
    dispatch( updateProductSearchValue( { productSearch: '' } ) );
    dispatch( changeLandingTab( { value: false, tabValue: configuration.checkLandingTab.tabValue } ) );
  }
}

const closeDialog = ( reason, setDialog, setShowDialog ) => {
  if ( reason !== 'backdropClick' ) {
    setDialog( '' );
    setShowDialog( false );
  }
}

const clearAllConfiguration = async ( props: any ) => {
  const { res, setErrorMessage, onCloseDialog, t, handleSuccess, dispatch } = props
  if ( res.error ) {
    handleError( res, setErrorMessage, onCloseDialog, t, dispatch )
  } else {
    dispatch( clearOptionalItem() )
    handleSuccess( EActionType.ClearAll );
  }
}
const resetActiveTab = ( configuration, applicationSettings, dispatch: AppDispatch ) => {//reset to previous active tab while reset cofiguration from submodel
  const modelPath = UrlHelper.getSearchParameter( EUrlParams.Model );
  const rootModel = configuration?.data?.product.id
  let activeTab = applicationSettings?.productsActiveTab[rootModel]?.tabIndex;
  if ( modelPath ) {
    activeTab = applicationSettings?.productsActiveTab[modelPath]?.tabIndex;
    const path = modelPath
      ? [rootModel, ...modelPath.split( '.' )]
      : [rootModel];
    if ( isSubModelAssigned( configuration, path.slice( 1 ) ) ) {
      dispatch( setCurrentActiveTab( { activeTab: activeTab } ) );
    }
  } else {
    dispatch( setCurrentActiveTab( { activeTab: activeTab } ) );
  }
}

/**
 * Renders the action panel which includes functionality for 
 * saving, canceling and set preferences
 * @param {IComponentProps} props the properties for the action panel component
 * @returns {JSX.Element} the action panel component
 */

export const ActionPanel = () => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState( false );
  const [dialog, setDialog] = useState<string | ReactElement>( '' );
  const [searchValue, setSearchValue] = useState( '' );
  const [errorMessage, setErrorMessage] = useState( '' );
  const [header, setHeader] = useState( '' );
  const [dialogType, setDialogType] = useState( '' );
  const [searchMatches, setSearchMatches] = useState( [] );
  const [searchCount, setSearchCount] = useState( 0 );
  const languageCode = getLanguageCode();
  const navigate = useNavigate();
  const configurationId = UrlHelper.getSearchParameter( EUrlParams.ConfigurationId );

  const applicationSettings = useSelector( ( state: AppState ) => state.applicationSettings );
  const showCode = useSelector( ( state: AppState )=> state.userSettings.showCode )
  const userPreferences = useSelector( ( state: AppState ) => state.userPreferences )
  const configuration = useSelector( ( state: AppState ) => state.configuration )
  const dispatch = useDispatch<AppDispatch>();

  const { token, auth } = GetToken();
  const loadProduct = async () => {
    await dispatch( loadConfiguration( { configurationId: configurationId, languageCode: languageCode, token: token, viewId: SessionStore.get( ESessionStore.ViewId ) } ) )
    dispatch( setIsConfigurationChanged( { isConfigurationChanged: false } ) );
    resetActiveTab( configuration, applicationSettings, dispatch );
  }

  useEffect( () => {
    tabChanged( configuration, setSearchValue, dispatch )
  }, [configuration.checkLandingTab.isTabChanged] )

  useEffect( () => {
    setSearchMatches( applicationSettings.searchMatches )
    setSearchCount( applicationSettings.searchCount.searchCount )
  }, [JSON.stringify( applicationSettings.searchMatches ), applicationSettings.searchCount.searchCount] )

  useEffect( () => {
    dispatch( setUserSettings( { showCode: userPreferences?.showCode } ) )
  }, [userPreferences?.showCode] )

  const onResetDialog = () => {
    setDialogType( EActionType.Reset );
    setErrorMessage( '' )
    setHeader( t( 'resetDialog.resetChanges' ) );
    setDialog( t( 'resetDialog.resetConfirmationMessage' ) );
    setShowDialog( true );
  }

  const onClearAllDialog = () => {
    setDialogType( EActionType.ClearAll );
    setErrorMessage( '' )
    setHeader( t( 'clearAllDialog.clearAllChanges' ) );
    setDialog( t( 'clearAllDialog.clearAllConfirmationMessage' ) );
    setShowDialog( true );
  }

  const onCloseWithoutSavingDialog = () => {
    setDialogType( EActionType.CloseWithoutSaving );
    setErrorMessage( '' )
    setHeader( t( 'closeWithoutSavingDialog.closeWithoutSavingChanges' ) );
    setDialog( t( 'closeWithoutSavingDialog.closeWithoutSavingConfirmationMessage' ) );
    setShowDialog( true );
  }

  const onCloseDialog = ( _event?: any, reason?: any ) => {
    closeDialog( reason, setDialog, setShowDialog )
  }

  const handleSuccess = ( type: string ) => {

    switch ( type ) {
      case EActionType.Reset: {
        setShowDialog( false );
        loadProduct();
        break;
      }
      case EActionType.ClearAll: {
        setShowDialog( false );
        dispatch( setIsConfigurationChanged( { isConfigurationChanged: false } ) );
        dispatch( setLoaderMessage( null ) );
        break;
      }
      default: break;
    }
  }

  const reset = () => {
    setErrorMessage( '' );
    dispatch( setLoaderMessage( EActionType.Reset ) );
    NotifyAPI.reset( { configurationId: SessionStore.get( ESessionStore.ConfigurationId ), token} ).then( ( ) => {
      handleSuccess( EActionType.Reset );

    } ).catch( ( err ) => {
      handleError( err, setErrorMessage, onCloseDialog, t, dispatch );
    } )
  }

  const clearAll = () => {
    const viewId: string = getViewId();
    onCloseDialog();
    setErrorMessage( '' )
    dispatch( setLoaderMessage( EActionType.ClearAll ) );
    dispatch( clearConfiguration( { configurationId: SessionStore.get( ESessionStore.ConfigurationId ), token: token, viewId: viewId } ) ).then( async ( res: any ) => {
      clearAllConfiguration( { res, setErrorMessage, onCloseDialog, t, configurationId, languageCode, token, applicationSettings, handleSuccess, dispatch } )
    } ).catch( ( err: any ) => {
      handleError( err, setErrorMessage, onCloseDialog, t, dispatch );
    } )
  }

  // Method to call the Notify API and handle error if Error Occurs
  const action = () => {
    if ( dialogType === EActionType.ClearAll ) {
      clearAll()
    } else if ( dialogType === EActionType.CloseWithoutSaving ) {
      closeTab( dialogType, onCloseDialog, navigate, configurationId, auth )
    } else {
      reset()
    }
  }

  const onToggleCodes = () => {
    dispatch( setUserSettings( {showCode: !showCode} ) );
  };

  const disableResetButton = disableResetIcon( configuration, applicationSettings );
  const disableClearAllButton = disableClearAllBtn( configuration );

  return (
    <AppAuthContext.Provider value={ token }>
      <Box className="rowFlex">
        <ActionDialog showDialog={ showDialog } onCloseDialog={ onCloseDialog } header={ header } errorMessage={ errorMessage } dialog={ dialog } action={ action } dialogType={ dialogType } />

        {token && <ProductSearchBar searchValue={ searchValue } setSearchValue={ setSearchValue } searchMatches={ searchMatches } searchCount={ searchCount } />}

        <LanguageSetting />

        {token && configurationId &&
          <>
            <CurrencySetting />

            <MessagesPopover />

            <ViewHandler />

            <Tooltip title={ t( 'userPreference.showCode' ) }>
              <span>
                <IconButton onClick={ onToggleCodes } disabled={ !configurationId } className="code-icon action-panel-buttons icon-font-size" data-testid="btn-show-code">
                  {displayCodeIcon( showCode )}
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title={ t( 'tooltip.reset' ) }>
              <span className="resetIcon">
                <Button onClick={ onResetDialog }
                  disabled={ disableResetButton }
                  className="eraserIcon"
                  data-testid="btn-reset-configuration"
                >{displayResetIcon( disableResetButton )}</Button>
              </span>
            </Tooltip>

            <Tooltip title={ t( 'tooltip.clearAll' ) }>
              <span>
                <IconButton
                  onClick={ onClearAllDialog }
                  disabled={ disableClearAllButton }
                  className="clearAllButton action-panel-buttons icon-font-size"
                  data-testid="btn-clear-configuration"
                >
                  <RestartAltIcon />
                </IconButton>
              </span>
            </Tooltip>

            {IsDefaultFlowInSessionStore() && <SaveAndClose configuration={ configuration } />}
            <CloseWithoutSaveDialog onCloseWithoutSavingDialog={ onCloseWithoutSavingDialog } t={ t } />

          </>
        }
      </Box>
    </AppAuthContext.Provider>
  );
};
