import { Table, TableCell, TableBody, TableContainer, TableRow } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IOrderingInstructionsProps, IFeatureOrFamily, IFeatureORIValue } from '../../types';
import { AppState, AppStore } from '../store';
import { NULL } from '../data/Constants';
import { getSubmodelORI, isSubmodelCountVariable } from '../services';


/**
 * This function returns the ORI values of the features
 * @param {IFeatureOrFamily}  variable to get the configurtion variable
 * @param {boolean}  showCode to get the user settings value
 * @returns {IFeatureORIValue} return the list of ORI values
 */

const getFeatureOrderingInstructions = ( variable:IFeatureOrFamily,showCode:boolean ):IFeatureORIValue[]=>{
  const featureOrderingInstructions:IFeatureORIValue[] = [];

  if( Array.isArray( variable.values ) ) {
    for ( const val of variable.values ) {

      const text = val?.pdmProperties?.orderingInstructions ?? null;
      if ( text && text !== NULL ) {
        const id = showCode ? `${val?.name} (${val.value})` : val.name;
        featureOrderingInstructions.push( { id: id, value: text } );
      }
      
    }
  }
  return featureOrderingInstructions;
}


/**
 * Renders the short sales text popup.
* @param {any} props the properties for the short sales text component
 * @returns {JSX.Element} the short sales text popup component
*/

export const OrderingInstructions = ( { variable }: IOrderingInstructionsProps ) => {
  const {t} = useTranslation();

  const showCode = AppStore.getState().userSettings.showCode;
  const configuration = useSelector( ( state: AppState )=> state.configuration )

  // condition to check ORI for PM & Submodel from Breadcrumbar
  if( variable?.hasORI ) {
    const orderingInstructionPM = configuration?.orderingInstructions.get( variable.id );
    return orderingInstructionPM ? <p>{orderingInstructionPM}</p> : <p className="noTextMessage">{t( 'orderingInstructions.noOrderingInstructions' )}</p>
  }


  const orderingInstruction = isSubmodelCountVariable( variable ) ? getSubmodelORI( configuration,variable ) : variable?.pdmProperties?.orderingInstructions
  const featureFamilyOrderingInstructions = orderingInstruction === NULL ? null : orderingInstruction;
  const featureOrderingInstructions = getFeatureOrderingInstructions( variable,showCode );

  return <>
    {featureOrderingInstructions.length <= 0 && !featureFamilyOrderingInstructions ? <p className="noTextMessage">{t( 'orderingInstructions.noOrderingInstructions' )}</p> :
      <><p>{featureFamilyOrderingInstructions}</p>
        {featureOrderingInstructions.length > 0 && <TableContainer className="table-container">
          <Table aria-label="Ordering Instruction Table">
            <TableBody>
              {featureOrderingInstructions.map( ( row ) =>
                <TableRow key={ row.id }>
                  {row.id && <TableCell className="table-cell">{row.id}</TableCell>}
                  <TableCell className="table-cell" >{row.value}</TableCell>

                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer> 
        } 
      </>    
    }
  </>
};