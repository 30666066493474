import React, { ReactNode, memo } from 'react';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

/**
 * Prepare single node of filter tree using TreeItem Component
 * @prop {name} is the name/label of the node
 * @prop {code} is the code of the tree node
 * @prop {children} is the child nodes
 * @returns {JSX.Element} a  TreeItem component.
 */

const TreeItemComponent = ( { name, code, children }: { name: string, code: string, children?: ReactNode } ) => {

  return (
    <>
      <TreeItem
        key={ code }
        itemId={ code }
        className="filter-container"
        data-testid="filter-container"
        label={ name }
      >
        {children}
      </TreeItem>
    </>
  )

}

export default memo( TreeItemComponent );