import { Breadcrumbs, Tooltip, IconButton } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch} from '../store';
import { ChevronRight } from '@mui/icons-material'
import { isSubModelAssigned, UrlHelper, getNameWithCode, GetToken, navigateToHome} from '../services';
import { Link,Navigate,useNavigate } from 'react-router-dom';
import { ISubModelInfo, IFeatureOrFamily,IExtendedConfigureResponse, IApplicationSettings } from '../../types';
import { SessionStore } from '../services/SessionStore';
import HomeIcon from '@mui/icons-material/Home';
import { ESessionStore, EUrlParams, EPageNames, EVariableType } from '../data/Constants';
import { useTranslation } from 'react-i18next';
import { InfoDialogIcon } from './InputComponents/InfoDialogIcon';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';
import { setCurrentActiveTab } from '../store/states/ApplicationSettingsSlice';

const hideBreadCrumbs = [EPageNames.LandingPage, EPageNames.Notified, EPageNames.CloseTab,EPageNames.ErrorPage];
const hideHomeButton = [EPageNames.LandingPage, EPageNames.Notified, EPageNames.CloseTab];

const HomeButton = ( { navigateToHomeScreen,t,token }: any ) => {
  return IsDefaultFlowInSessionStore() && token && hideHomeButton.indexOf( location.pathname ) < 0 ? <Tooltip title={ t( 'tooltip.back' ) }>
    <span>
      <IconButton
        onClick={ navigateToHomeScreen }
        className="home-icon icon-font-size"
        data-testid="btn-home-icon"
      >
        <HomeIcon />
      </IconButton>
    </span>
  </Tooltip> : null
    
}


/**
 * function to check whether PMP has ORI value or not
 * @param {IExtendedConfigureResponse} configuration configuration state
 * @param {string} productId product Id 
 * @returns {boolean} return true or false
 */

function hasOrderInst ( configuration:IExtendedConfigureResponse, productId:string ):boolean {
  if( configuration.orderingInstructions.get( productId ) ) {
    return true;
  }
  return false;
}


const getSubModelKey = ( modelCollection: string[] , index: number ) => {
  const tempModelCollection = [...modelCollection];
  tempModelCollection.length = index;
  return tempModelCollection.join( '.' );
} 

const getBreadcrumbName = ( productId: string, rootModel:ISubModelInfo,showCode:boolean,subModels:ISubModelInfo[] ) => {
  if( rootModel.id === productId ) {
    return getNameWithCode( {name:rootModel.name, id:rootModel.id} as IFeatureOrFamily, showCode );
  }
  const subModel = subModels.find( s => s.id === productId );
  if( subModel ) {
    return getNameWithCode( {name:subModel.name, id:subModel.id} as IFeatureOrFamily, showCode );
  }
  return productId;
}

const resetSelectedTab = ( id:string, breadcrumbs, applicationSettings:IApplicationSettings, dispatch:AppDispatch ) => {
  if( breadcrumbs.length > 1 ) { // counter this event only when breadcrumbs is on submodel
    const currentActiveTab = applicationSettings.productsActiveTab[`${id}`] ? applicationSettings.productsActiveTab[`${id}`].tabIndex : 0;
    dispatch( setCurrentActiveTab( { activeTab: currentActiveTab } ) );
  }
  SessionStore.set( ESessionStore.IsGuardedTab, false );
};

//THis method returns the href value for the product model
const getHref = ( productId: string, rootModel:ISubModelInfo, subModels:ISubModelInfo[], modelPath:string|null, path:string[] ):string => {
  const search = window.location.search;
  const params = new URLSearchParams( search );

  if( rootModel.id === productId && modelPath ) {
    params.delete( EUrlParams.Model );
    return window.location.pathname + '?' + params.toString();
  }

  const subModel = subModels.find( s => s.id === productId );
  const pathToProduct = path.slice( 1, path.indexOf( productId ) + 1 );

  if( subModel ) { 
    params.set( EUrlParams.Model, pathToProduct.join( '.' ) );
  }

  return window.location.pathname + '?' + params.toString();
}


/**
 * Renders the breadcrumb bar
 * @returns {JSX.Element} the breadcrumb component
 */

export const BreadcrumbBar = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {token} = GetToken() 
  const dispatch = useDispatch<AppDispatch>();

  //selectors
  const showCode = useSelector( ( state: AppState )=> state.userSettings.showCode )
  const configuration = useSelector( ( state: AppState )=> state.configuration )
  const applicationSettings = useSelector( ( state:AppState )=> state.applicationSettings );

  if( !configuration || !applicationSettings ) {
    return null;
  }

  if( !configuration.savedConfiguration ) {
    return <>  <HomeButton navigateToHomeScreen={ ()=> navigateToHome( { navigate } ) } t={ t } token={ token } />
      <Breadcrumbs className= "breadCrumb root" separator={ <ChevronRight /> }/>
    </> ;
  }  
  const { rootModel, subModels } = configuration.savedConfiguration.modelContext;
  const modelPath = UrlHelper.getSearchParameter( EUrlParams.Model );
  const path = modelPath 
    ? [rootModel.id, ...modelPath.split( '.' )]
    : [rootModel.id];
  const subModelCollection = modelPath ? modelPath.split( '.' ) : [] ;
  const currentModel = path[path.length - 1];

  if( configuration.data && !isSubModelAssigned( configuration, path.slice( 1 ) ) ) {
    const params = UrlHelper.deleteSearchParameter( EUrlParams.Model );
    return <Navigate to={ `${window.location.pathname}?${params}` } replace={ true }/>;
  }
  
  const breadcrumbs = path.filter( p => p ).map( ( p, index ) => ( { id: p, name: getBreadcrumbName( p, rootModel, showCode, subModels, configuration.shortSalesText ), 
    href: getHref( p,rootModel, subModels, modelPath, path ), modelKey: index === 0 ? p : getSubModelKey( subModelCollection, index )} ) );
  
  return <>
    <HomeButton navigateToHomeScreen={ ()=> navigateToHome( { navigate } ) } t={ t } token={ token } />
    <Breadcrumbs className= "breadCrumb root" separator={ <ChevronRight /> }>
      { token && configuration.data && hideBreadCrumbs.indexOf( location.pathname ) < 0 && breadcrumbs.map( b => 
        <span key={ b.id } className="breadcrumbs-li">
          <Tooltip key={ b.id } title={ b.name }>
            <Link className={ b.id === currentModel ? `${'item'} ${'activeItem'} ${'crumb-font-size'}` : 'item crumb-font-size' } to={ b.href } replace={ true } onClick={ () => resetSelectedTab( b.modelKey,breadcrumbs,applicationSettings, dispatch ) }>{ b.name }</Link>
          </Tooltip> 
          <InfoDialogIcon variable={ { id: b.id, name: b.name,variableType:EVariableType.Product,hasORI: hasOrderInst( configuration,b.id ) } }/>
        </span>
      ) }
    </Breadcrumbs>
  </>;
}