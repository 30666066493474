import { Autocomplete, TextField } from '@mui/material'
import React, { memo } from 'react'

/**
 * This component renders Autocomplete component to select values
 * @prop {options} is the option list for filter
 * @prop {disabled} is the attribute to disable/enable the Autocomplete filed
 * @prop {id} is the unique id for identify the element
 * @returns {JSX.Element} a  Autocomplete component.
 */

const AutoCompleteControl = ( { options, disabled, id }: { options: string[], disabled: boolean, id: string } ) => {

  return (
    <Autocomplete
      multiple
      value={ options }
      id={ id }
      options={ options }
      sx={ { m: 1, width: 165 } }
      disabled={ disabled }
      renderInput={ ( params ) => <TextField { ...params } /> }
      className="collapsed"
    /> )
}

export default memo( AutoCompleteControl );