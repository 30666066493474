import React, { ReactNode, memo } from 'react'
import TreeItemComponent from './TreeItemComponent'
import { ITreeNode } from '../../types'

/**
 * Prepare the parent and child list of simple tree view with tree item component
 * @prop {name} is the name/label of the node
 * @prop {code} is the code of the tree node
 * @prop {treeItems} is the list of child tree nodes 
 * @prop {children} is the child elements like clear icon
 * @returns {JSX.Element} a  list of TreeItem component.
 */

const TreeItemGroupComponent = ( { name, code, treeItems, children }: { name: string, code: string, treeItems: Array<ITreeNode>, children?: ReactNode } ) => {
  return (
    <>
      <div className="tree-group">
        <TreeItemComponent name={ name } code={ code }>
          {treeItems?.map( ( element: ITreeNode ) => {
            return <TreeItemComponent key={ element.code } name={ element.name } code={ element.code } />
          } )}
        </TreeItemComponent>
        {children}
      </div>
    </>
  )
}

export default memo( TreeItemGroupComponent );