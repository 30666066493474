import { ClickAwayListener, Drawer, PaperProps, Tab, Tabs, IconButton } from '@mui/material';
import React, {useEffect} from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch } from '../store';
import { getSelectableSections, isChildOf, getProductId, UrlHelper, getInitialTab } from '../services';
import { ESectionId, ESessionStore, EUrlParams } from '../data/Constants';
import { IApplicationSettings, IExtendedConfigureResponse, ISection } from '../../types';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { SessionStore } from '../services/SessionStore';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { InCompleteIcon } from './InputComponents/InCompleteIcon';
import { setActiveTab, setApplicationSettings, setCurrentActiveTab } from '../store/states/ApplicationSettingsSlice';

const StyleConstants = {
  extraSmallHeaderHeight: '89px',
  headerHeight: '105px'
};

const getSXPaperProps = ( applicationSettings:IApplicationSettings )=>{
  return {
    position: applicationSettings.pageSize.isSmall ? 'absolute' : 'initial',
    transition: '.15s',
    boxShadow: applicationSettings.pageSize.isSmall ? '8px 0 8px -8px black' : 'none',
    top: applicationSettings.pageSize.isExtraSmall ? StyleConstants.extraSmallHeaderHeight : StyleConstants.headerHeight,
    bottom: '0',
    height: applicationSettings.pageSize.isSmall ? 'unset' : '100%'
  }
}
function showTab( applicationSettings:IApplicationSettings ) {
  return !applicationSettings.pageSize.isSmall && applicationSettings.showSectionTabs || applicationSettings.pageSize.isSmall && applicationSettings.forceShowSectionTabs;
}

//Set the product wise active tab and current active tab if current model not exists in the productsActiveTab state
function setDefaultActiveTab( selectableSections:ISection[],applicationSettings:IApplicationSettings,modelPath:string|null,productModel:string , dispatch:AppDispatch ) {
  if( selectableSections.length > 0 ) { 
    const initialTab = getInitialTab( selectableSections );

    if( modelPath && !applicationSettings.productsActiveTab[modelPath] ) {//set the current active tab for submodels
      dispatch( setActiveTab( {modelId: modelPath, sectionID: selectableSections[initialTab].id, tabIndex: initialTab } ) );
    } else if( !applicationSettings.productsActiveTab[productModel] ) {//set the current active tab for root model
      dispatch( setActiveTab( {modelId: productModel, sectionID: selectableSections[initialTab].id, tabIndex: initialTab } ) );       
      dispatch( setCurrentActiveTab( { activeTab: initialTab } ) );
    }
  }
}

function changeTab( selectedSectionID:string,value:number,tabID:string, productModel:string,modelPath:string|null, dispatch: AppDispatch ) {
  if( modelPath ) {
    dispatch( setActiveTab( {modelId: modelPath,sectionID: tabID, tabIndex:value } ) )
  } else {
    dispatch( setActiveTab( {modelId:productModel,sectionID: tabID, tabIndex:value} ) )
  }
  SessionStore.set( ESessionStore.IsGuardedTab, selectedSectionID === ESectionId.Guarded );
}

function tabs( configuration:IExtendedConfigureResponse, selectableSections:ISection[], tabIndex:number ) {
  if ( !configuration.data?.sections ) {
    return []
  } else {
    return selectableSections?.map( ( s : ISection ) => { 
      const hideSection = s.suppressed ? 'd-none' : '';
      return <Tab key={ `${s.id}-${tabIndex}` } id={ s.id } label={ <span>{s.name} {!s.isComplete ? <InCompleteIcon/> : ''}</span> }
        value={ tabIndex++ } className={ s.isSearchValueMatched ? `tab-drawer-SearchValueMatched ${ hideSection }` : `tab-drawer-tabs  ${ hideSection }` } iconPosition="end" 
        icon={ s.id === ESectionId.Guarded ? <LockOutlinedIcon /> : '' }
      /> 
    } )
  }
}

/**
 * Renders the section tabs on the left side on the home screen.
 * Gets automatically hidden on smaller screens
 * @returns {JSX.Element} the tab drawer component
 */
export const TabDrawer = ( ) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const applicationSettings = useSelector( ( state: AppState )=> state.applicationSettings )
  const configuration = useSelector( ( state: AppState )=> state.configuration )

  if( !configuration || !applicationSettings ) {
    return null;
  }

  const productModel = getProductId();
  const modelPath = UrlHelper.getSearchParameter( EUrlParams.Model );    
  const show = showTab( applicationSettings );
  const config = JSON.parse( JSON.stringify( configuration ) )
  const {sections:selectableSections} = getSelectableSections( config );

  const onTabChange = ( _: React.SyntheticEvent<Element, Event>, value : number ) =>{
    dispatch( setCurrentActiveTab( { activeTab: value} ) );
    changeTab( selectableSections[value]?.id,value,tabs( configuration,selectableSections,tabIndex )[value].props.id,productModel,modelPath, dispatch );      
  };

  const onClickAway = ( e: MouseEvent | TouchEvent ) => !isChildOf( e.target as HTMLElement, 'tabDrawerToggle' ) && 
  applicationSettings.forceShowSectionTabs && 
  dispatch( setApplicationSettings( { forceShowSectionTabs: false } ) );


  const paperProps: Partial<PaperProps<'div', unknown>> = {
    sx: getSXPaperProps( applicationSettings ),
    className: classNames( 'w-0', { ['drawerOpen']: show } )
  };

  const tabIndex = 0;

  const DrawerHeader = styled( 'div' )( ( ) => ( {
    display: 'flex',
    alignItems: 'center'
  } ) );

  useEffect( () => {    
    setDefaultActiveTab( selectableSections,applicationSettings,modelPath,productModel, dispatch );   
  },[ selectableSections.length] )

  useEffect( () => {   
    if( configuration.productSearchValue !== '' ) { 
      const index = selectableSections.findIndex( s => s.isSearchValueMatched === true );
      SessionStore.set( ESessionStore.IsGuardedTab, selectableSections[index]?.id === ESectionId.Guarded );
      dispatch( setCurrentActiveTab( { activeTab: index !== -1 ? index : applicationSettings.activeTab } ) );
    }
  },[ configuration.productSearchValue] )

  const onToggleDrawer = () => {
    if ( applicationSettings.pageSize.isSmall ) {
      dispatch( setApplicationSettings( {
        forceShowSectionTabs: !applicationSettings.forceShowSectionTabs,
        forceShowSummary: false,
      } ) );
    } else {
      dispatch( setApplicationSettings( {
        showSectionTabs: !applicationSettings.showSectionTabs,
      } ) );
    }
  };


  return <>{!show ? <div className="tabDrawerMini">
    <IconButton id="tabDrawerToggle" onClick={ onToggleDrawer } >  <KeyboardDoubleArrowRightIcon className={ 'pinIcon' }/></IconButton>
   
    <span className="navigateTabLabel rotate180" onClick={ onToggleDrawer }>{t( 'tabSection.navigation' )}</span>
  </div> : <ClickAwayListener onClickAway={ onClickAway }>
    <Drawer variant="permanent" open PaperProps={ paperProps } className="h-100">
      <DrawerHeader>
        <IconButton onClick={ onToggleDrawer } className="left-arrow" data-testid="left-arrow">
          <KeyboardDoubleArrowLeftIcon className={ 'pinIcon' }/>
        </IconButton>
      </DrawerHeader>
          
      <Tabs value={ applicationSettings.activeTab } onChange={ onTabChange } orientation="vertical" variant="scrollable" scrollButtons={ false } visibleScrollbar className="tab-toggle">
        { tabs( configuration,selectableSections,tabIndex ) }
      </Tabs>
    </Drawer>
  </ClickAwayListener>
  }
  </>
}
