import { EConfigurationType, EFilterRootOption } from '../../data/Constants';
import { ITreeNode, IFilterOption, IFilterNode, IMyConfigurationFilter, IProdcutFilter, IProduct, IProductFilterOptions, ISimpleTreeNode } from '../../../types';

const configNameRegEx = /(^((\([A-Za-z0-9]+\))+|([A-Za-z0-9]+))+((([ -_])[A-Za-z0-9]+)*(([ -_])?(\([A-Za-z0-9]+\))+)*)*)$/;

/**
 * This function do the add filter selection and desection including root node.
 * @param {itemIds} itemIds list of selected filter item code
 * @param {treeNode} treeNode current node action performed
 * @param {filteredCodes} filteredCodes all the filtered codes
 * @param {availableFilterCodes} availableFilterCodes  all the codes available for filter
 * @returns {selectedCodes} selectedCodes final codes to show on the ui
 */

export const handleSelectionOfMultiSelectOnChange = ( itemIds: Array<string>, treeNode: ISimpleTreeNode, filteredCodes: IFilterOption, availableFilterCodes: IFilterOption ): IFilterOption => {
  const selectedCodes = { ...filteredCodes };
  if ( itemIds.includes( treeNode.enumValue ) && !filteredCodes[treeNode.type].includes( treeNode.enumValue ) ) {
    selectedCodes[treeNode.type] = [...availableFilterCodes[treeNode.type], treeNode.enumValue];
  } else if ( itemIds.includes( treeNode.enumValue ) && selectedCodes[treeNode.type].includes( treeNode.enumValue ) ) {
    selectedCodes[treeNode.type] = itemIds.filter( ( code ) => code !== treeNode.enumValue );
  } else if ( !itemIds.includes( treeNode.enumValue ) && selectedCodes[treeNode.type].includes( treeNode.enumValue ) ) {
    selectedCodes[treeNode.type] = [];
  } else if ( !itemIds.includes( treeNode.enumValue ) && itemIds.length === availableFilterCodes[treeNode.type].length ) {
    selectedCodes[treeNode.type] = [...availableFilterCodes[treeNode.type], treeNode.enumValue];
  } else {
    selectedCodes[treeNode.type] = [...itemIds];
  }
  return selectedCodes;
}

/**
 * This Function helps prepare the filter codes that are available for product portfolio
 * @param {productFilter} productFilter object contains all the root elements
 * @returns {filterCodes} all the available code for the filter
 */

export const prepareTreeCodes = ( productFilter: { channels: IFilterNode[], business: ITreeNode[] } ): IProdcutFilter => {
  const filterCodes: IProdcutFilter = { channels: [], business: [] };
  if ( !productFilter ) {
    return filterCodes;
  }
  productFilter.channels.forEach( ( node: IFilterNode ) => {
    filterCodes.channels.push( node.code );
  } )

  productFilter.business.forEach( ( node: ITreeNode ) => {
    filterCodes.business.push( node.code );
  } );

  return filterCodes;
}

/**
 * This Function helps prepare the filter codes that are availalbe for my configuration
 * @param {myConfigurationFilter} myConfigurationFilter object contains all the root elements
 * @returns {filterCodes}  all the available code for the filter
 */

export const prepareMyConfigurationTreeCodes = ( myConfigurationFilter: { channels: IFilterNode[], applications: IFilterNode[] } ): IMyConfigurationFilter => {
  const filterCodes: IMyConfigurationFilter = { channels: [], applications: [] };
  if ( !myConfigurationFilter ) {
    return filterCodes;
  }
  myConfigurationFilter.channels.forEach( ( node: IFilterNode ) => {
    filterCodes.channels.push( node.code );
  } )

  myConfigurationFilter.applications.forEach( ( node: IFilterNode ) => {
    filterCodes.applications.push( node.code );
  } );

  return filterCodes;
}

/**
 * This function helps prepare the countries available for the selected product.
 * @param {IProduct[]} prodList is the product portfolio list
 * @param {string} productId is the selected product
 * @returns {countries} available countries for the product id
 */

export const getAvailableCountries = ( prodList: IProduct[], productId: string ): IProduct['countries'] | [] => {
  const productData: IProduct | undefined = prodList.find( x => {
    return x.productId === productId;
  } )
  return productData ? productData.countries : [];
}

/**
 * This function add the agcodes of the selected business filter item to send in the request payload
 * @param {IProductFilterOptions} prodFilter is the product filter option
 * @param {IProdcutFilter} selectedCodes is the selected filter codes
 * @param {string} type is the type 'Business | Channel'
 * @returns {selectedCodes} is the ag code merged with selected codes
 */

export const getSelectedCodesWithAgCodes = ( prodFilter: IProductFilterOptions, selectedCodes: IProdcutFilter, type: string ): IProdcutFilter => {
  if ( type === EFilterRootOption.Business.toLowerCase() ) {
    prodFilter.business.forEach( ( item: ITreeNode ) => {
      if ( selectedCodes[type].includes( item.code ) && item.articleGroups ) {
        selectedCodes[type] = [...selectedCodes[type], ...item.articleGroups];
      }
    } )
  }
  return selectedCodes;
}

/**
 * This section helps to get the name of the selected filter item, to show in the collapsed mode
 * @param {ISimpleTreeNode} treeItem is the simple tree node
 * @param {IFilterOption} selectedCodes is the selected code form the filter
 * @returns {namesForShow} is the name of the selected codes to show on the ui below to the filter
 */

export const getSelectedCodeNames = ( treeItem: ISimpleTreeNode, selectedCodes: IFilterOption ): Array<string> | [] => {
  const namesForShow: Array<string> = [];
  treeItem.treeItems.forEach( ( item: ITreeNode ) => {
    if ( selectedCodes[treeItem.type].includes( item.code ) ) {
      namesForShow.push( item.name );
    }
  } );
  return namesForShow;
}

/**
 * This function remove the rootnode from the selected code.
 * @param {Array<string>} selectedCodes is the selected code form the filter
 * @param {string} removeCode remove the 'Channel | Business | Application' from the selected codes
 * @returns {selectedCodes} return the selected for the request payload
 */

export const filterIds = ( selectedCodes: Array<string>, removeCode: string ): Array<string> => {
  selectedCodes = selectedCodes.length > 0 ? selectedCodes.filter( ( selectedCode: string ) => selectedCode !== removeCode ) : selectedCodes;
  return selectedCodes;
}

/**
 * This function helps to find if the response is empty or not to show the no data found message
 * @param {IProductFilterOptions} filterOptions is the object contains the 'channel, business' 
 * @returns true || false based on value is available or not in the channel | business
 */

export const isFilterDataAvailable = ( filterOptions: IProductFilterOptions ): boolean => {
  return filterOptions.business.length > 0 || filterOptions.channels.length > 0 ? true : false;
}

/**
 * This Function helps to validate the configuration name while crete configuration.
 * @param {string} name is the name of the configuration 
 * @returns true || false
 */

export const validateName = ( name: string ): boolean => {
  return name === '' || name.length > 2 && name.length <= 40 && configNameRegEx.test( name )
}

export const formatDateString = ( dateValue: string ) => {
  return new Date( dateValue ).toLocaleString( 'en-GB', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  } ).toString().replace( '/', '-' ).replace( ',', '' );
}

export const addStateValues = ( state: number ) => {
  switch ( state ) {
    case 0:
      return EConfigurationType.Active;
    case 1:
      return EConfigurationType.ReadOnly;
    case 2:
      return EConfigurationType.Temp;
    default:
      return '';
  }
}