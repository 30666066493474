import {
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent
} from '@mui/material';
import React, { useState, useContext } from 'react';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import {useTranslation} from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch} from '../store';
import { ConfigurationApi } from '../api/ConfigurationApi';
import { SessionStore } from '../services/SessionStore';
import { AppAuthContext } from '../services/Contexts';
import { UrlHelper } from '../services';
import { EUrlParams } from '../data/Constants';
import DraggableComponent from './DraggableComponent';
import { setError } from '../store/states/ErrorSlice';

export const ExportConfigurationSummary = ( {type}:any ) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState( false );
  const token = useContext( AppAuthContext );
  const configurationId = UrlHelper.getSearchParameter( EUrlParams.ConfigurationId ) ?? SessionStore.get( 'configurationId' );
  const [value, setValue] = useState( 'single' );
  const exportSummary = () => {
    const isMultipleSheet = value !== 'single';
    ConfigurationApi.exportConfigurationBomExcel( { token, configurationId, isMultipleSheet } ).then( ( response ) => {
      if ( !response.error ) {
        const fileName = response.headers['content-disposition'].split( 'filename=' )[1];
        const url = window.URL.createObjectURL( new Blob( [response.data] , { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' } ) );
        const link = document.createElement( 'a' );
        link.href = url;
        link.setAttribute( 'download', `${fileName}` );
        document.body.appendChild( link );
        link.click();
      }else{
        dispatch( setError( response.error ) );
      }  
    } )
    handleClose();
  };

  const handleInputChange = ( e: SelectChangeEvent ) => {
    setValue( e.target.value )
  }
  
  const handleClose = () => {
    setOpen( false );
  };

  const handleClickOpen = () => {
    setOpen( true );
  };

  return <>
    <Tooltip title={ t( 'labels.exportConfiguration' ) }>
      <IconButton className={ `exportIcon ${type && type === 'detailed' ? 'detailedExportIcon' : 'quickExportIcon'}` }
        onClick={ handleClickOpen } data-testid="exportIcon-btn"
      >
        <SystemUpdateAltIcon />
      </IconButton>
    
    </Tooltip>

    <Dialog className="common-dialog-style cursor-move" open={ open } PaperComponent={ DraggableComponent } aria-labelledby="draggable-dialog-title" >
      <DialogTitle className="header">
        {t( 'labels.exportConfiguration' )}
      </DialogTitle>
      <DialogContent className="action-panel-dialog-content" >
        <RadioGroup
          aria-labelledby="dynamic-input-control"
          name="single-select-control-group"
          onChange={ handleInputChange }
          className="w-100"
          value={ value }
        >
          <FormControlLabel label={ t( 'exportConfigurationDialog.singleSheet' ) } value= "single" control={ <Radio /> } />
          <FormControlLabel label={ t( 'exportConfigurationDialog.multipleSheet' ) } value= "multi" control={ <Radio /> } />
        </RadioGroup>
      </DialogContent>
      <DialogActions >
        <Button className="text-capitalize" onClick={ exportSummary } data-testid="exportSummary-btn">{t( 'button.download' )}</Button>
        <Button className="text-capitalize" aria-label="close" onClick={ handleClose }>{t( 'button.cancel' )}</Button>
      </DialogActions>
    </Dialog>
  </>
};