import { EActionType, UserAssignmentsScope, IdFormat, PriorityValue, ESessionStore, EVariableType, EPageNames, EConfigitProperty } from '../../data/Constants';
import { createVariableAssignments, getConflict, addPdmProperties, isValidConfigurableSection, isSubmodelCountVariable, getValueById, IsAppOpenedByExtApp, IsAppOpenedInIframe } from '../../services';
import { SessionStore } from '../../services/SessionStore';
import { appSettings } from '../../settings';
import { ILoadConfiguration, IConfigurationAction, IExtendedConfigureResponseOptional, IExtendedConfigureResponse, IContextDataCountMap, IConfiguration, IContextData, ISection, IConflictHierarchy, IAssignment, IContextDataModel, IVariableAssignment, IImagesData, IPriceData, ISalesText, IMaterialPropertyAssociations, IConfigurationVariable } from '../../../types';
import { IsDefaultFlowInSessionStore } from '../../services/SessionStoreHelperFunctions';


const createOptionalItems = ( data: ILoadConfiguration ) => {
  const optionalItems = new Map<string, boolean>();
  if ( data.optionalItems ) {
    for ( const key of Array.from( data.optionalItems?.keys() ) ) {
      optionalItems.set( data.optionalItems[key], true );
    }
  }
  data.optionalItems = optionalItems;
}

export const loadedConfiguration = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional )=>{
  if ( action.data ) {
    createOptionalItems( action.data )
  }
  const variableAssignments = createVariableAssignments( action.data.userAssignments )
  const newState = { ...state, savedConfiguration: action.data, contextData: getContextData( action.data ), assignments: variableAssignments,access:action.access };
  const data = configured( action, newState )
  return {
    savedConfiguration: action.data, 
    contextData: data?.contextData, 
    access:action.access,
    conflict: data?.conflict,
    data: data?.data,
    assignments: data?.assignments,
    isInvalid: data && ( data.isInvalid === false || data.isInvalid ) ? data.isInvalid : null,
    acceptedChanges: data?.acceptedChanges
  }
}

export const configured = ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional ) => {
  action.configuration = action.configuration ? action.configuration : action.data.configureResponse;
  if ( !action.configuration ) {
    return null;
  }
  const assignmentsToRemove = action.configuration.removedAssignments?.variableAssignments;
  let assignments = state.assignments ? [...state.assignments] : [];
  if( action.apiType === EActionType.ClearAll ) {
    assignments = assignments.filter( assignedmentVal => UserAssignmentsScope.find( userVal => userVal === assignedmentVal.variableId ) ) //Update UserAssignmentsScope in Constant file if new static id adds up
  }
  const conflict = state.isInvalid !== false ? null : getConflict( { ...state, data: { ...action.configuration } } as IExtendedConfigureResponse );
  const shortSalesText = state.shortSalesText;
  
  if ( assignmentsToRemove ) {
    assignments = removeAssignments( assignmentsToRemove, shortSalesText, assignments );
  }
  const args = action.configuration.arguments?.Configuration || {};
  const submodels = Object.keys( args ).filter( e => {
    return e.endsWith( IdFormat.Suffix.SubmodelId ) && args[e] >= 1
  } );
  submodels.forEach( m => {
    const ass = assignments.find( a => a.variableId === m );
    if ( !ass ) {
      assignments.push(
        { assignmentType: 'Singleton', variableId: m, value: args[m].toString(), exclude: false, priority: PriorityValue.High }
      )
    } else if ( Number( ass.value ) >= 1 ) {
      ass['priority'] = PriorityValue.High;
    }
  } )
  sanitizeConfiguration( action.configuration, state.contextData );
  const countVariables: IContextDataCountMap = {};
  state.contextData?.countVariables && Object.keys( state.contextData?.countVariables ).forEach( ( k ) => {
    countVariables[k] = null;
  } )
  const bundledFeatures: { [key: string]: boolean } = {};
  mapCountAndBundleFeatures( action.configuration.sections, countVariables, bundledFeatures );
  
  //Update the variable and value  configit properties using  content folder propert Associations response
  action.configuration.sections = addPdmProperties( action.configuration.sections,state.propertyAssociations, state.propertyDefinition );
  //remove once real properties are available
  // fixSubModelViews( action.configuration );
  return checkInvalid( { ...state, contextData: { ...state.contextData, countVariables: { ...countVariables } }, bundledFeatures: bundledFeatures }, action, conflict, assignments, assignmentsToRemove )
}


const sanitizeConfiguration = ( configuration: IConfiguration, contextData: IContextData | null ) => {
  configuration.sections = configuration.sections?.filter( ( s ) => sanitizeSection( contextData, s ) );
}
  
const sanitizeSection = ( contextData: IContextData | null, section: ISection ) => {
  section.sections = section.sections?.filter( ( s ) => sanitizeSection( contextData, s ) );
  return isValidConfigurableSection( contextData, section );
}
  
const checkInvalid = ( state: IExtendedConfigureResponseOptional, action: IConfigurationAction, conflict: { assignmentsToRemove: IConflictHierarchy; assignmentsBefore: IAssignment[]; } | null, assignments: IAssignment[], assignmentsToRemove: string | any[] | undefined ) => {
  if ( state.isInvalid === null ) {
    if ( assignmentsToRemove?.length ) {
      return { conflict, data: action.configuration, assignments, isInvalid: true, acceptedChanges: false, contextData: state.contextData };
    } else {
      return { conflict, data: action.configuration, assignments, isInvalid: false, acceptedChanges: false , contextData: state.contextData};
    }
  } else {
    if ( state.isInvalid ) {
      //If user accepts proposed changes
      return { conflict: null, data: action.configuration, assignments, isInvalid: false, acceptedChanges: true, contextData: state.contextData }
    }
    return { conflict, data: action.configuration, assignments, isInvalid: state.isInvalid, contextData: state.contextData };
  }
}
  
/**
   * Generates and returns the context data from model context
   * @param {ILoadConfiguration} data The data received from read api call
   * @returns {IContextData} context data which maps model id to model context
   */
function getContextData( data?: ILoadConfiguration ) {
  if ( !data ) {
    return { models: null }
  }
  const modelContext = data.modelContext;
  const { rootModel, subModels } = modelContext
  const models: { [key: string]: IContextDataModel } = { [rootModel.id]: { ...rootModel, isRoot: true } };
  if ( rootModel.id ) {
    SessionStore.set( ESessionStore.ProductId, rootModel.id );
  }
  const countVariables: IContextDataCountMap = {};
  subModels.forEach( ( sm ) => {
    models[sm.id] = sm
    if ( sm.subModelVariableIds?.length ) {
      sm.subModelVariableIds?.forEach( ( v ) => {
        countVariables[v] = null;
      } )
    }
  } );
  
  return { models, countVariables }
}
  
/**
   * Returns an object that maps the count variables from configure response to the count variables ids in context data
   * @param {ISection[]} sections the sections received in configure response
   * @param {IContextDataCountMap} countVariables the object to store the map
   * @param {bundledFeatures} bundledFeatures the object to store the map of bundle features
   * @returns {void}
   */
  
function mapCountAndBundleFeatures( sections: ISection[], countVariables: IContextDataCountMap, bundledFeatures: { [key: string]: boolean } ) {
  const setVariable = ( varible: IConfigurationVariable ) => {
    varible.values.filter( val => val.state.isAssigned )?.forEach( val => {
      const vl = val.properties?.find( vp => vp.id === EConfigitProperty.BundleContent );
      vl?.value?.toString().split( ',' ).forEach( vid => bundledFeatures[vid] = true );
    }
    )
    if ( varible.id in countVariables || isSubmodelCountVariable( varible ) ) {
      countVariables[varible.id] = varible;
    }
  }
  sections.forEach( ( section: ISection ) => {
    section.variables.forEach( varible => {
      setVariable( varible );
    } )
    section.sections?.length && mapCountAndBundleFeatures( section.sections, countVariables, bundledFeatures );
  } )
}
  

/**
 * This function set the current  short salestext to the state
 * @param { IExtendedConfigureResponse } state configuration state
 * @param { IConfigurationAction } action api payload
 * @returns { void }
 */

function getShortSalesTextData( state: IExtendedConfigureResponse, action: IConfigurationAction ):void {
  const shortSalesText = new Map<string,ISalesText>( state?.shortSalesText?.get( action.modelId ) )
  action.salesText.salesTexts?.forEach( ( text: ISalesText ) => {
    if ( text.id ) {
      shortSalesText.set( text.id, text );
    }
  } )
  
  state.shortSalesText?.set( action.modelId,shortSalesText );
}
  

/**
 * This function set the current  long and short salestext to the state
 * @param { IConfigurationAction } action api payload
 * @param { IExtendedConfigureResponse } state configuration state
 * @returns { void }
 */

export function setSalesText ( action: IConfigurationAction, state: IExtendedConfigureResponse ):void {
  if ( action.apiType === 'short' ) {
    getShortSalesTextData( state, action );
  } else {
    const longSalesText = new Map<string,ISalesText>( state?.longSalesText?.get( action.modelId ) )
    action.salesText?.salesTexts?.forEach( ( text: ISalesText ) => {
      if ( text.id ) {
        longSalesText.set( text.id, text );
      }
    } )
    state.longSalesText?.set( action.modelId,longSalesText )  
  }
}
  

/**
 * This function set the current  price  to the state
 * @param { IConfigurationAction } action api payload
 * @param { IExtendedConfigureResponse } state configuration state
 * @returns { void }
 */

export function getPriceData ( action: IConfigurationAction, state: IExtendedConfigureResponse ):void {
  const productPrice = new Map<string,IPriceData>( state.price?.get( action.modelId ) );

  action?.price?.prices?.forEach( ( price: IPriceData ) => {
    if ( price.materialCode && !( price.materialCode in productPrice ) ) {
      productPrice.set( price.materialCode, price );
    }
  } )
  state.price?.set( action.modelId, productPrice )
}
  

/**
 * This function remove the conflicting assignments from the existing assignemnts
 * @param {IVariableAssignment[]} assignmentsToRemove remove assignemnts 
 * @param {Map<string, Map<string, ISalesText>>} shortSalesText short sales text data
 * @param { IAssignment[] } assignments current assignemnts 
 * @returns {IAssignment[]} return the assignments
 */

const removeAssignments = ( assignmentsToRemove: IVariableAssignment[], shortSalesText: Map<string, Map<string, ISalesText>> | undefined, assignments: IAssignment[] ):IAssignment[] => {
  const validAssignments = [...assignments];
  for ( const assignmentToRemove of assignmentsToRemove ) {

    const variableText = getValueById( shortSalesText, assignmentToRemove.variable.id );
    assignmentToRemove.variable.shortSalesText = appSettings.UseShortSalesText && shortSalesText && variableText ? variableText.text : null;

    const valueText = getValueById( shortSalesText,assignmentToRemove.value.value?.toString() );
    assignmentToRemove.value.shortSalesText = appSettings.UseShortSalesText && valueText ? valueText.text : null;

    let index = -1;
    //Compare the feature value name for Numeric features and rest all type features compare the value
    if( assignmentToRemove.variable.valueType === EVariableType.Number ) {
      index = validAssignments.findIndex( a => a.variableId === assignmentToRemove.variable.id && a.value === assignmentToRemove.value.name );
    }else{
      index = validAssignments.findIndex( a => a.variableId === assignmentToRemove.variable.id && a.value === assignmentToRemove.value.value );
    }
    if( index >= 0 ) {
      validAssignments.splice( index, 1 )
    }
  }
  return validAssignments;
}
  

/**
 * This function set the current  price  to the state
 * @param { IConfigurationAction } action api payload
 * @param { IExtendedConfigureResponse } state configuration state
 * @returns { void }
 */

export function setProductImages ( action: IConfigurationAction, state: IExtendedConfigureResponse ):void {
  action.productImages = action.productImages && typeof action.productImages === 'string' ? JSON.parse( action.productImages ) : action.productImages;
  
  const updateProductImages = new Map<string,IImagesData>( state.productImages?.get( action.modelId ) );
  if( action.modelId && action.productImages ) {
    action.productImages.productImages?.forEach( ( data: IImagesData ) => {
      if ( data.materialCode && !( data.materialCode in updateProductImages ) ) {
        updateProductImages.set( data.materialCode, data );
      }
    } )

    state.productImages?.set( action.modelId, updateProductImages )
  }

}


/**
 * This function set the current  property associations   to the state
 * @param { IConfigurationAction } action api payload
 * @param { IExtendedConfigureResponseOptional } state configuration state
 * @returns { void }
 */

export function setPropertyAssociations ( action: IConfigurationAction, state: IExtendedConfigureResponseOptional ):void {
  
  if ( action.modelId ) {
    const propertyAssociations = new Map<string, IMaterialPropertyAssociations>( state.propertyAssociations?.get( action.modelId ) );
    if( action.propertiesAssociation ) {
      action.propertiesAssociation?.forEach( ( data: IMaterialPropertyAssociations ) => {
        if ( data.materialCode && !( data.materialCode in propertyAssociations ) ) {
          propertyAssociations.set( data.materialCode, data );
        }
      } )
    }
    state.propertyAssociations?.set( action.modelId, propertyAssociations )
  }
}

export const getErrorData = ( t ) => {
  const isAppAccessedByEstore = ( IsAppOpenedByExtApp() || IsAppOpenedInIframe() ) && !IsDefaultFlowInSessionStore();
  const errorMessage = isAppAccessedByEstore ? 'errorMessages.EStore.ErrorMessage' : 'errorMessages.CPQ.ErrorMessage';
  return {response:null, code: 404, message: t( errorMessage ), page: EPageNames.Configurator};
}
