import {
  MenuItem,
  Tooltip,
  IconButton,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
  Box
} from '@mui/material';
import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch, AppStore } from '../store';
import { getLanguageCode,getProductId,IsAppOpenedInIframe,IsAppOpenedByExtApp } from '../services';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import { ILanguages } from '../../types';
import { DefaultLanguage, ApplicationSupportedLanguages, PopperModifier, ESessionStore, EPageNames } from '../data/Constants';
import { appSettings } from '../settings';
import { AppAuthContext } from '../services/Contexts';
import { SessionStore } from '../services/SessionStore';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';
import { setUserSettings } from '../store/states/UserSettingsSlice';
import { setUserPreferences } from '../store/states/UserPreferencesSlice';
import { getSalesText } from '../store/states/ConfigurationSlice';

function onPreferenceLangChange( { location, changeLanguage, token, userPreferences, dispatch } ) {
  if ( userPreferences.preferenceChanged ) {
    const langCode = getLanguageCode( location.pathname, true );
    if ( langCode ) {
      dispatch( setUserSettings( { language: langCode } ) )
      changeLanguage( langCode );
    }
    dispatch( setUserPreferences( { preferenceChanged: false } ) )
    if ( location.pathname !== EPageNames.LandingPage ) {
      if ( appSettings.UseShortSalesText ) {
        dispatch( getSalesText( { language: langCode, token: token, modelId: getProductId(), apiType: 'short', showError: false } ) );
      }
      dispatch( getSalesText( { language: langCode, token: token, modelId: getProductId(), apiType: 'long', showError: false } ) );
    }
  } else {
    dispatch( setUserSettings( { language: userPreferences.language } ) );
    changeLanguage( userPreferences.language );
  }
}

const onHandleLanguage = async ( value, handleLanguageProps ) => {
  const { availableLanguages, changeLanguage, handleClose, token, dispatch } = handleLanguageProps
  const lang = availableLanguages.find(
    ( _: { id: string } ) => _.code === value
  ); 
  lang?.code && changeLanguage( lang?.code );
  handleClose();
  dispatch( setUserSettings( { language: lang?.code } ) );
  if ( token && location.pathname !== EPageNames.LandingPage && location.pathname !== EPageNames.Logout ) {
    const bcpLangCode = lang?.code;
    const productModel = getProductId();

    if ( IsAppOpenedInIframe() || IsAppOpenedByExtApp() ) {
      SessionStore.set( ESessionStore.Language, lang?.code.split( '-' )[0] );
      SessionStore.set( ESessionStore.Country, lang?.code.split( '-' )[1] );
    }
    if ( appSettings.UseShortSalesText ) {
      dispatch( getSalesText( { language: bcpLangCode, token: token, modelId: productModel, apiType: 'short', showError: false } ) );
    }
    dispatch( getSalesText( { language: bcpLangCode, token: token, modelId: productModel, apiType: 'long', showError: false } ) );

  }
};

const handleLangCode = ( language: string, changeLanguage:( language:string )=> Promise<void> ):void =>{
  // to convert the page into desired lang for LandingPage when click of home button
  if( location.pathname === EPageNames.LandingPage && IsDefaultFlowInSessionStore() ) {
    changeLanguage( language )
  }
}

/**
 * Renders the ViewHandler dropdown where the user can change the view
 * @returns {JSX.Element} the viewHandler Element
 */
export const LanguageSetting = () => {
  const token = useContext( AppAuthContext );
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>( null );
  const open = Boolean( anchorEl );
  const id = open ? 'scroll-playground' : undefined;
  const [arrowRef, setArrowRef] = React.useState( null );

  //selectors
  const featureFlags = AppStore.getState().claimsData.featureFlags;
  const userLanguage = useSelector( ( state: AppState ) => state.userSettings.language )
  const userPreferences = useSelector( ( state: AppState )=> state.userPreferences )
  const configuration = useSelector( ( state: AppState )=> state.configuration )

  const dispatch = useDispatch<AppDispatch>();
  const languageRender = featureFlags?.Language; 

  const handleClick = ( event: React.MouseEvent<HTMLElement> ) => {
    setAnchorEl( anchorEl ? null : event.currentTarget );
  };
  const handleClose = () => {
    setAnchorEl( null )
  }
 
  useEffect( () => {
    const langCode = getLanguageCode( location.pathname );
    langCode && changeLanguage( langCode );
  }, [] );
  
  useEffect( () => {
    onPreferenceLangChange( {location,changeLanguage,token,userPreferences, dispatch } )
  }, [userPreferences.language] );

  useEffect( () => {
    handleLangCode( userLanguage,changeLanguage );
  }, [JSON.stringify( configuration.pmLanguages ),location.pathname] );

  const changeLanguage = async ( lng: string ) => {
    try {
      i18n.changeLanguage( lng.replace( '-', '_' ) );
    } catch ( err ) {
      console.log( err )
    }

  };
  let availableLanguages: ILanguages[] = configuration?.pmLanguages
    ? configuration?.pmLanguages
    : DefaultLanguage.Value;
  if ( location.pathname === EPageNames.LandingPage || location.pathname === EPageNames.Logout || location.pathname === EPageNames.CloseTab || location.pathname === EPageNames.Notified ) {
    availableLanguages = ApplicationSupportedLanguages
  }
  if ( !languageRender?.active || !languageRender?.visible ) {
    return null
  }
  return <>
    <Tooltip title={ t( 'userPreference.language' ) }>
      <span>
        <IconButton disabled={ availableLanguages.length <= 1 || !IsDefaultFlowInSessionStore() || languageRender && !languageRender?.editable } className={ `languageContainer icon-font-size ${anchorEl ? 'activeIcon' : ''}` } onClick={ handleClick } aria-describedby={ id } data-testid="btn-language">
          <LanguageIcon fontSize="small" />
          {`${getLanguageCode( location.pathname )}`}
          <KeyboardArrowDownIcon />
        </IconButton>
      </span>
    </Tooltip>
    <Popper className="zindex-100" id={ id } open={ open } anchorEl={ anchorEl } placement="bottom" role="tooltip" modifiers={ [
      ...PopperModifier,
      {
        name: 'arrow',
        enabled: true,
        options: {
          element: arrowRef,
        }
      }
    ] }
    >
      <div role="tooltip">
        <Box component="div" id="arrow" data-popper-arrow className="popperStyle arrow" ref={ setArrowRef } />
        <Box >
          <Paper >
            <ClickAwayListener onClickAway={ handleClose }>
              <MenuList
                autoFocusItem={ open }
                id="language-menu"
                aria-labelledby="language-button"
                data-testid="menu-language"
              >
                {availableLanguages.map( ( value: ILanguages ) => 
                  userLanguage !== value.code &&
                  <MenuItem
                    onClick={ () => onHandleLanguage( value.code, { availableLanguages, changeLanguage, handleClose, token, dispatch } ) }
                    value={ value.name }
                    key={ value.code }
                    data-testid="userSettings-list"
                  >
                    {value.name}&nbsp;({value.code})
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>

        </Box>
      </div>
    </Popper>
  </>
};