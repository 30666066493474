import { Box } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch, AppStore } from '../store';
import { ConflictDialog, SectionLayout, getLower, UnsupportedBrowser } from '.';
import { getSelectableSections, UrlHelper, onVariableChange, getLanguageCode, setOptional, checkFeatureSelected, checkIsMarkedOptional, isSubModelAssigned, getCurrencyCode, getProductId, showConfigurationSummaryTabDrawer } from '../services';
import { IApplicationSettings, IConfigurationVariable, ICurrentSectionReturnType, IExtendedConfigureResponse, ILanguages, IValueType, ILastChange, IPriceListMap, IExtendedConfigureResponseOptional, IConfigureRequest, IClaimsData, INamed, IConfiguration, ISection, IPropertyDefinition, ISubModelDispatchProps } from '../../types';
import { SessionStore } from '../services/SessionStore';
import { useTranslation } from 'react-i18next';
import { EVariableType, ESessionStore, EChangeType, EUrlParams, EErrorTypes, DefaultUserSettings, EMessagesType, ProductScope, EPageNames } from '../data/Constants'
import { countryCodes } from '../localization';
import { appSettings } from '../settings';
import WarningIcon from '@mui/icons-material/Warning';
import { AppAuthContext } from '../services/Contexts';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';
import { isFeatureVisible } from '../services/ClaimsHelper';
import { isValidCurrency } from '../services/Price';
import { getUserDetails } from '../store/states/UserSlice';
import { setApplicationSettings, setIsConfigurationChanged, setSearchValues } from '../store/states/ApplicationSettingsSlice';
import { resetError } from '../store/states/ErrorSlice';
import { getUserPreferences, setUserPreferences } from '../store/states/UserPreferencesSlice';
import { configure, getCurrencyList, getPMLanguages, getPrice, getPriceListMap, getProductImages, getPropertyAssociations, getSalesText, getSubModelData, getViewList, loadConfiguration, setConfiguration, setConfigureAPI, setLoaderMessage, setOptionalItems } from '../store/states/ConfigurationSlice';
import { getClaims } from '../store/states/ClaimsDataSlice';
import { setMessage, setOpenPopoverOnLoad } from '../store/states/MessagesSlice';
import Disclaimer from './Disclaimer';
import { SaveAndClose } from './SaveAndClose';
import classNames from 'classnames';
import { t } from 'i18next';
import { handleError } from '../api/HttpUtil';

const changeOptionaItems = ( value: IValueType, variable: IConfigurationVariable, lastChange: ILastChange, request: IConfigureRequest, configuration: IExtendedConfigureResponse ) => {
  const vrblType = variable.variableType;
  let prop;
  if ( lastChange.type === EChangeType.Remove ) {
    prop = value;

  } else if ( lastChange.type === EChangeType.Change ) {
    if ( ( vrblType === EVariableType.Number || vrblType === EVariableType.String ) && lastChange.value === '' ) {
      prop = variable.id;

    } else if ( vrblType === EVariableType.Singleselect && lastChange.value !== lastChange.valueBefore ) {
      prop = lastChange.valueBefore;

    }
  }
  const flag = checkIsMarkedOptional( prop as string, variable );
  if ( flag ) {
    configuration.savedConfiguration.optionalItems = setOptional( prop as string, false, variable );
    // adding optionalItems in request payload on uncheck or clear of feature
    addOptionalItemsToRequest( request, configuration )
  }
}

/**
 * To get the current section to display in configurator
 * @param {IExtendedConfigureResponse} configuration the configuration state
 * @param {IApplicationSettings} applicationSettings the application settings state
 * @returns {ICurrentSectionReturnType} the current section to display and view availability for the model
 */

export function getCurrentSection( configuration: IExtendedConfigureResponse, applicationSettings: IApplicationSettings ): ICurrentSectionReturnType {
  if ( !configuration.data?.sections ) {
    return { section: null, viewUnavailable: false };
  }
  const { activeTab } = applicationSettings;
  const { sections, viewUnavailable, searchCount, searchMatches } = getSelectableSections( configuration );
  if ( !sections.length ) {
    return { section: null, viewUnavailable };
  }
  // To check if all the sections in the current model are isItemsAvailable/hidden
  const hiddenAll = sections.filter( x => x.isItemsAvailable === true );
  if ( hiddenAll.length === sections.length ) {
    return { section: null, viewUnavailable, selectableItemsUnavailable: true, searchCount, searchMatches };
  }
  return { section: sections[activeTab], viewUnavailable, undefined, searchCount, searchMatches };
}

const configureApiCall = ( param ) => {
  const { request, token, applicationSettings, lastChange, dispatch } = param;
  dispatch( setLoaderMessage( 'configure' ) );
  dispatch( configure( { request: request, token: token, language: getLanguageCode(), lastChange: lastChange } ) ).then( response => {
    const res = response.payload;
    dispatch( setLoaderMessage( null ) );
    dispatch( setConfigureAPI( { value: true } ) );
    if ( res.error && !applicationSettings.isConfigurationChanged ) { // if error on 1st time configuration changed
      dispatch( setIsConfigurationChanged( { isConfigurationChanged: false } ) );
    } else if ( !res.error && !applicationSettings.isConfigurationChanged && res.configuration.removedAssignments.variableAssignments.length > 0 ) {
      //if not error & changing for 1st time but there are conflicts 
      dispatch( setIsConfigurationChanged( { isConfigurationChanged: false } ) );
    } else { //if error but user is not changing for 1st time means there is change in configuration so enable button
      dispatch( setIsConfigurationChanged( { isConfigurationChanged: true } ) );
    }
  } )
}

async function getPriceListMapData( countryCode: string, token: string, dispatch: AppDispatch ) {
  const storedPriceListMap = SessionStore.get( ESessionStore.PriceListMap );
  let priceListMap = await ( storedPriceListMap ?? dispatch( getPriceListMap( { token: token } ) ) );
  if ( !storedPriceListMap ) {
    SessionStore.set( ESessionStore.PriceListMap, priceListMap );
  }
  priceListMap = priceListMap?.payload?.find( ( priceMap: IPriceListMap ) => priceMap.country === countryCode )?.map;
  priceListMap = priceListMap ?? countryCode;
  return priceListMap;
}

function getConfigurationId() {
  return UrlHelper.getSearchParameter( EUrlParams.ConfigurationId ) ?? SessionStore.get( ESessionStore.ConfigurationId );
}

function getUserMarket( configuration: IExtendedConfigureResponseOptional, applicationSettings: IApplicationSettings ) {
  const countryCode = configuration?.assignments?.find( res => {
    return res.variableId === ProductScope.MarketId
  } )?.['value'];

  //returns true when the Country/Market given by User is loaded in applicationSettings
  return countryCode === applicationSettings.currency.countryCode;
}


/**
 * This method call the submodel api to fetch the price
 * @param {IExtendedConfigureResponse} configuration the configuration state
 * @param {IApplicationSettings} applicationSettings application settings state
 * @param {string} token api token
 * @param {AppDispatch} dispatch dispatch the getPrice api
 * @returns {void}
 */

function getSubmodelPrice( configuration: IExtendedConfigureResponse, applicationSettings: IApplicationSettings, token: string, dispatch: AppDispatch ):void {
  if ( configuration.savedConfiguration && getUserMarket( configuration, applicationSettings ) ) {
    const countVariables = configuration?.contextData?.countVariables;
    const modelIds: string[] = [];
    const keys = Object.keys( countVariables );
    keys.forEach( variable => {
      const assignedValue = countVariables?.[variable]?.values?.find( v => v.state.isAssigned );
      if ( assignedValue && Number( assignedValue.value ) > 0 ) {
        modelIds.push( variable );
      }

    } );
    if ( modelIds.length > 0 && isValidCurrency( applicationSettings.currency.currencyCode ) ) {
      modelIds.forEach( mid => {
        const id = mid?.split( '.' )?.pop()?.split( '_' )[0];
        if ( SessionStore.get( ESessionStore.PriceListMap ) && !configuration?.price?.get( id ) ) {
          dispatch( getPrice( { token: token, countryCode: applicationSettings.priceListMap, currencyCode: applicationSettings.currency.currencyCode, modelId: id } ) )
        }
      } )
    }
  }
}


/**
 * This function call the salestext , price and image api for sub model
 * @param { ISubModelDispatchProps } param input prams
 * @returns { void }
 */

async function getSubmodelMarketingInfo( param:ISubModelDispatchProps ): Promise<void> {
  const { configuration, token, applicationSettings, model, dispatch } = param;
  const path = model?.split( '.' ) || [];
  const modelId = path.pop();
  if ( model && configuration.data && isSubModelAssigned( configuration, path ) ) {
    const languageCode = getLanguageCode();
    const bcpLangCode = languageCode;
    getSubModelShortSalesText( { configuration: configuration, modelId: modelId, bcpLangCode: bcpLangCode, token: token, dispatch: dispatch } );
    getSubModelPrice( { configuration: configuration, modelId: modelId, token: token, applicationSettings: applicationSettings, dispatch: dispatch } );
    getSubModelProductImages( { configuration, modelId, token, dispatch } );
    getSubModelSalesText( { configuration: configuration, modelId: modelId, bcpLangCode: bcpLangCode, token: token, dispatch: dispatch } );
    getSubModelFeatureData( { configuration: configuration, modelId: modelId, token: token, dispatch: dispatch } )
  }
}


/**
 * This function call the salestext api for sub model
 * @param { ISubModelDispatchProps } param input prams
 * @returns { void }
 */

function getSubModelShortSalesText( param:ISubModelDispatchProps ):void {
  const { configuration, modelId, languageCode, token, dispatch } = param;
  if ( appSettings.UseShortSalesText && !configuration?.shortSalesText?.get( modelId ) ) {
    dispatch( getSalesText( { language: languageCode, token: token, modelId: modelId, apiType: 'short', showError: false } ) );
  }
}


/**
 * this function call the product image api for sub model
 * @param { ISubModelDispatchProps } param input prams
 * @returns { void }
 */

function getSubModelProductImages( param:ISubModelDispatchProps ) {
  const { configuration, modelId, token, dispatch } = param;

  if ( modelId !== '' && !configuration?.productImages?.get( modelId ) ) {
    dispatch( getProductImages( { token: token, modelId: modelId } ) );
  }
}


/**
 * this function call the product image api for sub model
 * @param { ISubModelDispatchProps } param input prams
 * @returns { void }
 */

async function getSubModelPrice( param:ISubModelDispatchProps ) {
  const { configuration, modelId, token, applicationSettings, dispatch } = param;
  if ( isValidCurrency( applicationSettings.currency.currencyCode ) && !configuration?.price?.get( modelId ) ) {
    //Fetchig price list and sending countryCode and currencyCode based on price list mapping for fecthing price
    const countryCode = configuration.assignments?.find( ( a: { variableId: string; } ) => a.variableId === ProductScope.MarketId ).value;
    let priceListMap = await getPriceListMapData( countryCode, token, dispatch );
    priceListMap = priceListMap && priceListMap !== '' ? priceListMap : applicationSettings.priceListMap;
    const countryCurrency = SessionStore.get( ESessionStore.PriceListMap ) ? await dispatch( getCurrencyList( { token: token, countryCode: priceListMap } ) ).unwrap() : null;
    let currencyCode = getCurrencyCode( priceListMap, countryCurrency );
    currencyCode = currencyCode && currencyCode !== '' ? currencyCode : applicationSettings.currency.currencyCode;
    dispatch( getPrice( { token: token, countryCode: priceListMap, currencyCode: currencyCode, modelId: modelId } ) )
  }
}


/**
 * this function call the long salestext api for sub model
 * @param { ISubModelDispatchProps } param input prams
 * @returns { void }
 */

function getSubModelSalesText( param:ISubModelDispatchProps ) {
  const { configuration, modelId, languageCode, token, dispatch } = param;
  if ( !configuration.longSalesText?.get( modelId ) ) {
    dispatch( getSalesText ( { language: languageCode, token: token, modelId: modelId, apiType: 'long', showError: false } ) );
  }
}


/**
 * This function call the property associations api for sub model
 * @param { ISubModelDispatchProps } param input prams
 * @returns { Promise<boolean> } return the tru or false
 */

function callSubModelPropertyAssociation( param:ISubModelDispatchProps ) : Promise<boolean> {
  const { configuration, token, model, dispatch, setCanCallPropertyAssociation, canCallPropertyAssociation } = param;
  if ( canCallPropertyAssociation ) {
    setCanCallPropertyAssociation( false );
    //Using promise to avoid unnesessory api calls
    return new Promise( ( resolve ) => {
      const path = model?.split( '.' ) || [];
      const modelId = path.pop();
      // Add property assoction api call if value already exist for the submodal
      if ( modelId && !configuration.propertyAssociations?.get( modelId ) ) {
        const productModel = getProductId();
        dispatch( getPropertyAssociations( { token: token, packageId: productModel, productId: modelId, propertyDefiniton: configuration.propertyDefinition } ) )
          .then( () => {
            resolve( true );
          } );
      } else {
        resolve( true );
      }
    } );
  }
}


/**
 * This function  dispatch the action for fetch the sub model data for the current parent 
 * @param {ISubModelDispatchProps} param the input params
 */

function getSubModelFeatureData( param:ISubModelDispatchProps ):void {
  const { configuration, modelId, token, dispatch } = param;
  if( !configuration.subModelData.get( modelId ) ) {
    dispatch( getSubModelData( { token:token,modelId:modelId} ) );
  }
}


function getClassSelector( applicationSettings: IApplicationSettings ) {
  return applicationSettings.pageSize.isExtraSmall ? 0 : 1
}

function addOptionalItemsToRequest( request: IConfigureRequest, configuration: IExtendedConfigureResponse ) {
  request.optionalItems = configuration.savedConfiguration?.optionalItems ? [...configuration.savedConfiguration.optionalItems.keys()] : []
}

function changeOptional( param ) {
  const { value, id, variable, configuration, token, onChange, dispatch } = param;
  const configurationId = getConfigurationId();
  const vrblType = variable.variableType;
  const flag = value ? checkFeatureSelected( id, variable ) : true;// if checkbox checked 
  //check if feature is selected - not selected call onChange else call setOptionalItems api
  if ( flag ) { //feature has value 
    dispatch( setOptionalItems( { token: token, configurationId: configurationId, optionalItems: configuration.savedConfiguration?.optionalItems ? [...configuration.savedConfiguration.optionalItems.keys()] : [] } ) )
  } else { // feature not selected
    switch ( vrblType ) {
      case EVariableType.Number: {
        const lower = getLower( variable.values );
        onChange( variable, lower, 'optionalCheck' );
        break;
      }
      case EVariableType.String: onChange( variable, '-', 'optionalCheck' );
        break;
      default: onChange( variable, id.split( '.' ).pop(), 'optionalCheck' ); // In case of singleselect & multiselect send selected feature id
    }
  }
}

function setPMLanguages( response: { pmLanguages: [{ languages: ILanguages[] }] }, userSettings, loading, setLanguageSelection, dispatch: AppDispatch ) {

  const userLanguage = response?.pmLanguages[0]?.languages.find( lang => lang.code === userSettings.data.language );
  if ( !loading && ( typeof userLanguage !== 'undefined' || response?.pmLanguages[0]?.languages.length === 1 ) ) {
    setLanguageSelection( true );
  }
  if ( typeof userLanguage === 'undefined' ) {
    setLanguageSelection( true );
    dispatch( setUserPreferences( {
      language: userSettings.data.language,
      showCode: userSettings.data.showCode
    } ) )
  }
}

async function getPMMarketingInfo( params ) {
  const { bcpLangCode, token, productModel, currencyCode, priceListMap, dispatch } = params;
  if ( isValidCurrency( currencyCode ) && SessionStore.get( ESessionStore.PriceListMap ) ) {
    dispatch( getPrice( { token: token, countryCode: priceListMap, currencyCode: currencyCode } ) )
  }

  dispatch( getProductImages( { token: token, modelId: productModel } ) );
  if ( appSettings.UseShortSalesText ) {
    //To fetch the salesText data based on the UseShortSalesText flag
    dispatch( getSalesText( { language: bcpLangCode, token: token, modelId: productModel, apiType: 'short', showError: false } ) )
  }
  dispatch( getSalesText( { language: bcpLangCode, token: token, modelId: productModel, apiType: 'long', showError: false } ) )
  
  dispatch( getSubModelData( { token, modelId:productModel } ) )

}

function setDefaultUserSettings( dispatch: AppDispatch ) {
  dispatch( setUserPreferences( { ...DefaultUserSettings, isUserSettingsDefault: true } ) );
  return { data: DefaultUserSettings }
}

const setUserSettingsAndLanguages = async ( langSettingProps ) => {
  const { languageSelection, loading, setLanguageSelection, token, configurationId, dispatch } = langSettingProps
  if ( !languageSelection && !loading ) {
    let userSettings;
    const lang = UrlHelper.getSearchParameter( EUrlParams.Language ) ?? SessionStore.get( ESessionStore.Language );
    const contry = UrlHelper.getSearchParameter( EUrlParams.Country ) ?? SessionStore.get( ESessionStore.Country )
    if ( lang && contry ) {
      dispatch( setUserPreferences( {
        language: lang + '-' + contry,
        showCode: true
      } ) );
      userSettings = {
        data: {
          language: lang + '-' + contry,
          showCode: true
        }
      }
    } else {
      const res = await dispatch( getUserPreferences( { token: token } ) );
      userSettings = res.payload
      if ( !userSettings ) {
        userSettings = setDefaultUserSettings( dispatch )
      }
    }
    await dispatch( getPMLanguages( { configurationId: configurationId, token: token } ) )
      .unwrap().then( ( response: { pmLanguages: [{ languages: ILanguages[] }] } ) => {
        setPMLanguages( response, userSettings, loading, setLanguageSelection, dispatch )
      } )
  }
}

const langCodeValue = ( languageCode, urlLanguageCode, urlLanguage, dispatch ) => {
  if ( languageCode !== urlLanguageCode ) { //To set message when invalid language is passed
    dispatch( setMessage( {
      text: urlLanguage ? t( 'errorMessages.invalidLanguage', { urlLanguage: urlLanguageCode, fallbackLanguage: languageCode } ) : t( 'errorMessages.noLanguage', { fallbackLanguage: languageCode } ),
      type: EMessagesType.Warning
    } ) );
  }
}

const currencyCodeValue = ( isCurrencyValid: boolean, urlCurrency: string, claimsData: IClaimsData, dispatch ) => {
  if ( isCurrencyValid ) {
    return urlCurrency;
  } else if ( !isFeatureVisible( claimsData.featureFlags.ListPrice ) && isFeatureVisible( claimsData.featureFlags.Currency ) ) {
    dispatch( setMessage( {
      text: urlCurrency ? t( 'errorMessages.invalidCurrencyLPDisabled', { currency: urlCurrency } ) : t( 'errorMessages.noCurrency' ),
      type: EMessagesType.Warning
    } ) );
  } else if ( isFeatureVisible( claimsData.featureFlags.ListPrice ) ) {
    dispatch( setMessage( {
      text: urlCurrency ? t( 'errorMessages.invalidCurrency', { currency: urlCurrency } ) : t( 'errorMessages.noCurrency' ),
      type: EMessagesType.Warning
    } ) );
  }
  return 'NaN';
};

const popoverOnLoad = ( languageCode: string | INamed[], urlLanguageCode: string, isCurrencyValid: boolean, dispatch: AppDispatch ) => {
  if ( languageCode !== urlLanguageCode || !isCurrencyValid ) {
    dispatch( setOpenPopoverOnLoad( true ) );
  }
}
export const handleCurrencyCodeChange = ( isCurrencyValid: boolean, sessionCurrency: string, priceListMap: string, countryCurrency: { currency: string[]; }, claimsData: IClaimsData, dispatch ) => {
  let currencyCode = '';
  if ( sessionCurrency === null ) {//when no currency is passed from the url
    currencyCode = getCurrencyCode( priceListMap, countryCurrency );//gets the default currency for the country
    ( isFeatureVisible( claimsData.featureFlags.ListPrice ) || isFeatureVisible( claimsData.featureFlags.Currency ) ) && dispatch( setMessage( {
      text: t( 'errorMessages.noCurrency', { fallbackCurrency: currencyCode } ),
      type: EMessagesType.Warning
    } ) );
  } else {
    //if sessionCurrency is invalid currencyCode will be NaN else it is sessionCurrency
    currencyCode = currencyCodeValue( isCurrencyValid, sessionCurrency, claimsData, dispatch );
  }
  return currencyCode;
}

const loadProduct = async ( params ) => {
  const { languageSelection, loading, token, setLanguageSelection, setLoading,
    i18n, navigate, claimsData, dispatch } = params;
  //For PWA on edge, configurationId was not able to read it from url param hence reading it from session store. For chrome it will read from url param
  const configurationId = getConfigurationId();
  setUserSettingsAndLanguages( { languageSelection, loading, setLanguageSelection, token, configurationId, dispatch } )
  setLoading( true );
  if ( languageSelection ) {
    const languageCode = getLanguageCode();
    i18n.changeLanguage( languageCode.replace( '-', '_' ) );
    const res = await dispatch( getViewList( { configurationId: configurationId, token: token } ) );
    const viewResponse = res.payload;
    if ( viewResponse.error?.code === 400 || viewResponse.error?.code === 404 ) {
      dispatch( resetError() );
      navigate( '/Error', { replace: true, state: { type: EErrorTypes.InvalidConfiguration, code: viewResponse.error?.code, message: viewResponse.error?.message } } );
      return;
    }
    const result = await dispatch( loadConfiguration( { configurationId: configurationId, languageCode: languageCode, token: token, viewId: UrlHelper.getSearchParameter( EUrlParams.ViewId ) ?? SessionStore.get( ESessionStore.ViewId ) } ) );
    const response = result.payload;
    if ( response?.error || !configurationId ) {
      return;
    }
    const countryCode = response.data.userAssignments.find( ( userAssignment: { variableId: string; } ) => {
      return userAssignment.variableId === ProductScope.MarketId
    } )['value'];
    const priceListMap = await getPriceListMapData( countryCode, token, dispatch )
    const sessionCurrency = SessionStore.get( ESessionStore.Currency );
    let currencyCode = '';
    //finds the country from PriceListMap and gets the currency list for it
    const countryCurrency = SessionStore.get( ESessionStore.PriceListMap ) ? await dispatch( getCurrencyList( { token: token, countryCode: priceListMap } ) ).unwrap() : null;
    if ( !IsDefaultFlowInSessionStore() ) { //To handle when currency is passed from down-stream application      
      const urlLanguage = SessionStore.get( ESessionStore.Language );
      const urlCountry = SessionStore.get( ESessionStore.Country );
      const urlLanguageCode = urlLanguage + '-' + urlCountry; // Concatenate the language and country to get the locale code
      langCodeValue( languageCode, urlLanguageCode, urlLanguage, dispatch );
      const isCurrencyValid = Object.values( countryCodes.codes ).map( x => x.currencyCode ).includes( sessionCurrency );
      currencyCode = handleCurrencyCodeChange( isCurrencyValid, sessionCurrency, priceListMap, countryCurrency, claimsData, dispatch );
      //displays messages for invalid language and currency
      popoverOnLoad( languageCode, urlLanguageCode, isCurrencyValid, dispatch );
    } else {
      currencyCode = getCurrencyCode( priceListMap, countryCurrency );//To handle to get currency in case of default flow
    }
    const currency = {
      'countryCode': countryCode,
      'currencyCode': currencyCode,
      'locale': countryCodes.codes[countryCode]['locale']
    }
    dispatch( setApplicationSettings( { currency: currency, priceListMap: priceListMap } ) );
    const bcpLangCode = languageCode;
    const productModel = getProductId();
    getPMMarketingInfo( { bcpLangCode, token, productModel, currencyCode, priceListMap, dispatch } )
    if ( response.data.propertyDefinition == null ) {
      dispatch( getPropertyAssociations( { token: token, packageId: productModel, productId: productModel, propertyDefiniton: [] } ) );
    }
  }
}

function changeValue( params ) {
  const { request, requestType, configuration, token, applicationSettings, lastChange, value, variable, dispatch } = params;
  if ( !request ) {
    return;
  }
  if ( requestType !== 'optional' ) {
    changeOptionaItems( value, variable, lastChange, request, configuration );
  }
  if ( requestType === 'optionalCheck' ) {
    // adding optionalItems in request payload on check of Opt col when feature is not checked
    addOptionalItemsToRequest( request, configuration )
  }
  configureApiCall( { request, token, applicationSettings, lastChange, dispatch } );
}

const handleTabClose = event => {
  event.preventDefault();
  event.returnValue = '';
  return event.returnValue;
};

function addConfirmationListner( isChanged ) {
  if ( isChanged ) {
    window.addEventListener( 'beforeunload', handleTabClose, { capture: true } );
  } else {
    window.removeEventListener( 'beforeunload', handleTabClose, { capture: true } );
  }
}

/**
 * This function used to fetch the user details, claims and clear the pricelist from session
 * @param {string} token 
 * @param {userDetails} userDetails 
 * @param {claimsData} claimsData 
 * @param {AppDispatch} dispatch 
 */

function getPrimaryDetails( token, userDetails, claimsData, dispatch: AppDispatch ): void {
  //to remove PriceListMap key from SessionStore if page is reloaded
  if ( performance.getEntriesByType( 'navigation' )[0].type === 'reload' ) {
    SessionStore.remove( ESessionStore.PriceListMap )
  }
  if ( token && Object.keys( userDetails ).length === 0 ) {
    dispatch( getUserDetails( { token: token } ) );
  }
  if ( token && !claimsData.claims ) {
    dispatch( getClaims( { token: token } ) );
  }
}

//This function returns the message if current view is unavailable for the product model
function renderInValidViewMessage( viewUnavailable: boolean, selectableItemsUnavailable: boolean | undefined ) {
  return <Box className="root text-left align-top justify-left">
    {viewUnavailable && !selectableItemsUnavailable ? t( 'configurator.viewUnavailable' ) : t( 'configurator.noSelectableSections' )}
  </Box>;
}

//This function returns the boolean value based on the below checks
function checkInValidView( busy: boolean, currentSection: ISection | null, configurationData: IConfiguration ) {
  return !busy && !currentSection && configurationData;
}

//This function returns the boolean to set the initial configuration to the state
function IsSetConfiguration( configuration: IExtendedConfigureResponse ) {
  return configuration.data && ( configuration.data.isConfigComplete === false || configuration.data.isConfigComplete === true ) && configuration.isDataSet;
}

//This function check and return the boolean to load the Product
function checkLoadProduct( configuration: IExtendedConfigureResponse, loading: boolean, languageSelection: boolean ) {
  return !configuration.savedConfiguration && !loading || languageSelection || configuration.acceptedChanges
}

//This function returns the Disclaimer and SaveAndClose  component
function renderSaveAndClose( configuration: IExtendedConfigureResponse, isDefaultFlow: boolean, show: boolean ) {
  return <div className="disclaimer-addproduct">
    {!isDefaultFlow && <Disclaimer />}
    {!isDefaultFlow && !show && <SaveAndClose configuration={ configuration } />}
  </div>
}

/**
 * This function shows the error dialog if property definition state is empty
 * Reset the error window once get the property definition response from the Api
 * @param {IPropertyDefinition | null} propertyDefinitionMissingError 
 * @param {AppDispatch} dispatch 
 */

function handleProductModelError( propertyDefinitionMissingError: IPropertyDefinition | null, dispatch: AppDispatch ) {
  if ( propertyDefinitionMissingError != null ) {
    handleError( propertyDefinitionMissingError, EPageNames.Configurator );
  } else {
    dispatch( resetError() );
  }
}

function mandatoryConfigitPropertyMissing( configitProperty: boolean, dispatch: AppDispatch ) {
  if ( !configitProperty ) {
    dispatch( setMessage( {
      text: t( 'errorMessages.configitProperyNoActive' ),
      type: EMessagesType.Warning
    } ) );
  }
}

/**
 * Renders the currently selected Section and is responsible to handle the assignment functionality
 * @returns {JSX.Element} the configurator component with the currently selected section
 */
export const Configurator = () => {
  const token = useContext( AppAuthContext );
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState( false );
  const [languageSelection, setLanguageSelection] = useState( false );
  const [canCallPropertyAssociation, setCanCallPropertyAssociation] = useState( true );

  const navigate = useNavigate();
  const [serachParams] = useSearchParams();
  const model = serachParams.get( EUrlParams.Model ) ?? '';

  const dispatch = useDispatch<AppDispatch>();

  //selector
  const claimsData = AppStore.getState().claimsData;
  const busy = useSelector( ( state: AppState ) => state.busy )
  const applicationSettings = useSelector( ( state: AppState ) => state.applicationSettings )
  const userDetails = useSelector( ( state: AppState ) => state.user.details )
  const config = useSelector( ( state: AppState ) => state.configuration )
  const configuration = structuredClone( config )


  const { section: currentSection, viewUnavailable, selectableItemsUnavailable, searchCount, searchMatches } = getCurrentSection( configuration, applicationSettings );

  useEffect( () => {
    addConfirmationListner( applicationSettings.isConfigurationChanged )
  }, [applicationSettings.isConfigurationChanged] );

  useEffect( () => {
    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener( 'beforeunload', handleTabClose, { capture: true } );
    }
  }, [] )

  useEffect( () => {
    if ( checkLoadProduct( configuration, loading, languageSelection ) ) {
      //load product directly if no product was loaded before or user aceepts the changes
      loadProduct( { languageSelection, loading, token, setLanguageSelection, setLoading, i18n, navigate, claimsData, dispatch } );
    }
  }, [languageSelection] )

  useEffect( () => {
    handleProductModelError( config.propertyDefinitionMissingError, dispatch );
  }, [config.propertyDefinitionMissingError] )

  useEffect( () => {
    mandatoryConfigitPropertyMissing( config.configitPropertyInEffect, dispatch );
  }, [config.configitPropertyInEffect] )

  useEffect( () => {
    getPrimaryDetails( token, userDetails, claimsData, dispatch )
  }, [token] )

  useEffect( () => {
    //call price for submodel on page load
    getSubmodelPrice( config, applicationSettings, token, dispatch )
  }, [config.data, applicationSettings.currency] )

  useEffect( () => {
    //call price and sales text for submodel on model change
    getSubmodelMarketingInfo( { configuration: config, token, applicationSettings, model, dispatch } );
  }, [model, config.data] )

  useEffect( () => {
    callSubModelPropertyAssociation( { configuration, token, model, dispatch, setCanCallPropertyAssociation, canCallPropertyAssociation } ).then( res => {
      setCanCallPropertyAssociation( res );
    } );
  }, [model] )

  useEffect( () => {
    dispatch( setSearchValues( { searchCount: searchCount || { searchCount: 0 }, searchMatches: searchMatches || [] } ) );
  }, [JSON.stringify( searchMatches ), searchCount?.searchCount] )

  if ( !configuration || !applicationSettings ) {
    return null;
  }

  const onChange = ( variable: IConfigurationVariable, value: IValueType, requestType?: string ) => {
    const lastChange = { type: '', variableId: variable.id, value, valueBefore: null };
    const request = onVariableChange( configuration, variable, value, lastChange );
    changeValue( { request, requestType, configuration, token, applicationSettings, lastChange, value, variable, dispatch } )
  }


  if ( IsSetConfiguration( configuration ) ) {
    dispatch( setConfiguration( { data: configuration.data, contextData: configuration.contextData } ) )
  }


  // To display message when all the families in the section are hidden
  if ( currentSection?.isItemsAvailable || selectableItemsUnavailable ) {
    return <Box className="root text-left align-center d-flex noSelectableMessage">
      <WarningIcon className="warningIcon" /> &nbsp; {t( 'configurator.noSelectableItems' )}
    </Box>;
  }

  if ( checkInValidView( busy, currentSection, configuration.data ) ) {
    return renderInValidViewMessage( viewUnavailable, selectableItemsUnavailable );
  }

  const onOptionalChange = ( id: string, value: boolean, variable: IConfigurationVariable ) => {
    configuration.savedConfiguration.optionalItems = setOptional( id, value, variable );
    changeOptional( { value, id, variable, configuration, token, onChange, dispatch } )
  }

  const show = showConfigurationSummaryTabDrawer( applicationSettings );
  const isDefaultFlow = IsDefaultFlowInSessionStore();

  const classSelector = getClassSelector( applicationSettings );
  const padding = ['p-10px', 'p-15px'];
  return <Box className={ `configurator-root root ${padding[classSelector]}` }>
    <Box className={ classNames( { ['configurator-content']: isDefaultFlow, ['configurator-content-with-disclaimer']: !isDefaultFlow && !applicationSettings.pageSize.isMedium, ['medium-configurator-content']: !isDefaultFlow && applicationSettings.pageSize.isMedium } ) }>
      {currentSection && <SectionLayout section={ currentSection } onChange={ onChange } onOptionalChange={ onOptionalChange } isDefaultFlow={ isDefaultFlow } />}
    </Box>
    {renderSaveAndClose( configuration, isDefaultFlow, show )}
    <ConflictDialog />
    <UnsupportedBrowser />
  </Box>;
}
