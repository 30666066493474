import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IMyConfiguration } from '../../../../types';
import { ETabValue, EUrlParams } from '../../../data/Constants';
import { changeLandingTab } from '../../../store/states/ConfigurationSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/AppStore';
import { useTranslation } from 'react-i18next';
import { formatDateString, addStateValues } from '../../../store/Helpers/LandingHelper';

/**
 * 
 * @prop {config} is the configuration card item info
 * @prop {index} is the configuration index
 * @returns {JSX.Element}
 */

export const ConfigurationCardItem = ( { config, index }: { config: IMyConfiguration, index: number } ) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const [isDesktop, setIsDesktop] = useState( window.innerWidth > 1450 );

  const updateMedia = () => {
    setIsDesktop( window.innerWidth > 1450 );
  };

  /**
     * Add the resize event on initial load and remove this event on component destroy
     */

  useEffect( () => {
    window.addEventListener( 'resize', updateMedia );
    return () => window.removeEventListener( 'resize', updateMedia );
  } );

  return (
    <Grid key={ config.configurationId } item xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
      <Card sx={ { border: '1px solid grey' } } className="minh-100" id={ 'mc-' + index } data-testid={ 'mcCard' + index }>
        <CardContent>
          <div className="cardLayoutMainDiv">
            <div>
              <img src="public\png\SamplePMImage.png" style={ { height: '80px', width: '80px', marginLeft: '5px', marginTop: '10px' } } alt="sample-image" />
            </div>

            <div style={ { display: 'block', marginLeft: '10px', width: '100%' } }>
              <div className="cardLayoutChildProdDiv">
                <Tooltip placement="bottom-start" title={ config.productDescription }>
                  <span className="overflow-text product-description">{config.productDescription}</span > <span className="product-description"> ({config.productId})</span>
                </Tooltip>
              </div>

              <div>
                <span className="configurationName" ><b>{t( 'labels.name' )}: </b>
                  <span className="configName" onClick={ () => {
                    dispatch( changeLandingTab( { value: true, tabValue: ETabValue.Product } ) )
                    navigate( '/?' + EUrlParams.ConfigurationId + '=' + config.configurationId, { replace: true } );
                  } }
                  >{config.name}</span>
                </span>
              </div>

              <div style={ { display: 'flex' } }>
                <div className="cardLayoutChildDiv w-40">
                  <span><b>{t( 'labels.Id' )}:</b> {config.configurationId.substring( 0, 18 )}</span>
                  <span style={ { display: 'flex' } }><b>{t( 'labels.country' )}</b>: <span className={ config.countryName.length > 18 && !isDesktop ? 'overflow-country' : '' }>{config.countryName} </span> ({config.countryCode})</span>
                  <span><b>{t( 'labels.state' )}:</b> {addStateValues( config.state )}</span>
                </div>

                <div className="cardLayoutChildDiv w-60">
                  <span><b>{t( 'labels.createdOn' )}</b>: {formatDateString( config.createdOn ) + ' UTC'}</span>
                  <span><b>{t( 'labels.modifiedOn' )}</b>: {formatDateString( config.modifiedOn ) + ' UTC'}</span>
                  <span><b>{t( 'labels.application' )}</b>: {( config.createdFromName )} </span>
                </div>
              </div>
            </div>
          </div>
        </CardContent>

      </Card>
    </Grid>
  );
}